import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { style: {"position":"relative"} }
const _hoisted_3 = { class: "overlay-text" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "index-box" }
const _hoisted_6 = { class: "project-title" }
const _hoisted_7 = {
  key: 0,
  class: "project-subtitle"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = { style: {"display":"flex","justify-content":"flex-end","width":"95%"} }
const _hoisted_10 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","align-items":"flex-start","flex":"1 0 0","align-self":"stretch"}
}
const _hoisted_11 = { style: {"display":"flex","justify-content":"space-between","align-items":"center","align-self":"stretch","padding":"0px","margin-bottom":"0"} }
const _hoisted_12 = { style: {"margin":"0","color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal"} }
const _hoisted_13 = { style: {"display":"flex","padding":"0px","justify-content":"flex-end","align-items":"center","gap":"10px","align-self":"stretch"} }
const _hoisted_14 = { style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400"} }
const _hoisted_15 = ["href"]

import { onMounted, ref, nextTick } from 'vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";

export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDetailView',
  props: {
    project_ID: {}
  },
  setup(__props: any) {

const props = __props;
const projects_detail_list = ref([]);
const quill_list = ref<Array<Quill>>([]);
const title_image_link = ref("");
const project_title = ref("");

const file_table = ref(false)
const selectedProject = ref(null);

const error = ref<boolean>(false);

const handleClose = (done: () => void) => {
    file_table.value = false; // 显式地关闭抽屉
    done();
    selectedProject.value = null; // 清除选中的项目
}

onMounted(async () => {
    document.title="北京城市实验室-项目详情"
    try {
        const project_overview = await axios.get(BaseUrl + '/api/f/projects/all_projects/project_title_image?projectID=' + (parseInt(props.project_ID)-1).toString());
        project_title.value = project_overview.data.projectName;
        title_image_link.value =  "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/project/"+project_title.value+"/cover";
        
        const response = await axios.get(BaseUrl + '/api/f/projects/project_detail?project_ID=' +  (parseInt(props.project_ID)-1).toString());
        projects_detail_list.value = response.data;
        const item_count = projects_detail_list.value.length;
        nextTick(() => {
            for (let index = 0; index < projects_detail_list.value.length; index++) {
                const viewerId = `#viewer${index}`;
                const readonlyquill = new Quill(viewerId, {
                    theme: 'snow',
                    readOnly: true,
                    modules: {
                        toolbar: false,
                    }
                });
                readonlyquill.root.innerHTML = projects_detail_list.value[index].subproject_content;
                quill_list.value.push(readonlyquill);
            }

            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' }) // 平滑滚动到目标元素
                }
            }
        });

        console.log("初始化完成");
    } catch (err: any) {
        error.value = true;
    }

});

return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (error.value)
      ? (_openBlock(), _createBlock(_component_el_image, {
          key: 0,
          src: defaultImage,
          alt: "404 Not Found",
          fit: "scale-down"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_image, {
              src: title_image_link.value,
              style: {"width":"100%","height":"15vw"},
              fit: "cover",
              "preview-src-list": [title_image_link.value]
            }, null, 8, ["src", "preview-src-list"]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mask" }, null, -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(props.project_ID + '-' + project_title.value), 1)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projects_detail_list.value, (projects_detail, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "project-container",
              id: `project-index-${projects_detail.subproject_ID}`
            }, [
              _createVNode(_component_el_divider),
              _createVNode(_component_el_row, { class: "project-row" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_image, {
                        class: "project-image",
                        src: projects_detail.subproject_images[0]?.subproject_image_link || defaultImage,
                        fit: "scale-down",
                        "preview-src-list": projects_detail.subproject_images.map(img => img.subproject_image_link)
                      }, null, 8, ["src", "preview-src-list"]),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("p", null, _toDisplayString(index+1), 1)
                      ])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_el_col, {
                    span: 16,
                    class: "project-details"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("h2", _hoisted_6, _toDisplayString(projects_detail.subproject_name), 1),
                        (projects_detail.subproject_subname)
                          ? (_openBlock(), _createElementBlock("h3", _hoisted_7, _toDisplayString(projects_detail.subproject_subname), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", {
                        id: 'viewer' + index,
                        class: "richtext"
                      }, null, 8, _hoisted_8),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_el_button, {
                          class: "download-bottom",
                          onClick: () => { selectedProject.value = projects_detail; file_table.value = true }
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode(" Download")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ], 8, _hoisted_4))
          }), 128)),
          _createVNode(_component_el_drawer, {
            modelValue: file_table.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((file_table).value = $event)),
            title: "Files",
            direction: "rtl",
            size: "30%",
            "before-close": handleClose,
            style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"normal"}
          }, {
            default: _withCtx(() => [
              (selectedProject.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedProject.value.subproject_files, (file, file_index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: file_index,
                        style: {"margin":"0","display":"flex","flex-direction":"column","align-items":"flex-start","align-self":"stretch"}
                      }, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_el_tooltip, {
                            class: "box-item",
                            effect: "dark",
                            content: /\.[a-zA-Z0-9]+$/.test(file.subproject_file_name)
                                    ? file.subproject_file_name
                                    : decodeURIComponent(file.subproject_file_link.substring(file.subproject_file_link.lastIndexOf('/') + 1)),
                            placement: "left"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_text, {
                                style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal","margin-top":"5px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","width":"18vw"},
                                truncated: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(/\.[a-zA-Z0-9]+$/.test(file.subproject_file_name)
                                            ? file.subproject_file_name
                                            :
                                            decodeURIComponent(file.subproject_file_link.substring(file.subproject_file_link.lastIndexOf('/')
                                                + 1))), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["content"]),
                          _createElementVNode("p", _hoisted_12, _toDisplayString(file.subproject_file_size), 1)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("p", _hoisted_14, [
                            _createElementVNode("a", {
                              href: file.subproject_file_link,
                              style: {"color":"inherit"}
                            }, "Download", 8, _hoisted_15)
                          ])
                        ]),
                        _createVNode(_component_el_divider, { style: {"margin":"3px"} })
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
  ]))
}
}

})