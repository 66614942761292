import { createRouter, createWebHistory , RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from '@/components/Layout.vue'
import ProjectView from '@/views/ProjectView.vue'
import ProjectDetailView from '@/views/ProjectDetailView.vue'
import DataIndexView from '@/views/DataIndexView.vue'
import DataDatailView from '@/views/DataDatailView.vue'
import CourseIndexView from '@/views/CourseIndexView.vue'
import CourseInfoView from '@/views/CourseInfoView.vue'
import MemberView from '@/views/MemberView.vue'
import AboutView from '@/views/AboutView.vue'
import ConferenceView from '@/views/ConferenceView.vue'
import LongyingView from '@/views/LongyingView.vue'
import UpdateView from '@/views/UpdateView.vue'
import SearchView from '@/views/SearchView.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    children:[
      {
        path:'',
        component:HomeView
      }
    ]
  },
  {
    path: '/longy',
    name: 'longy',
    component: Layout,
    children:[
      {
        path:'',
        component:LongyingView
      }
    ]
  },
  {
    path: '/projects',
    name: 'project',
    component: Layout,
    children:[
      {
        path:'',
        component:ProjectView
      }
    ]
  },
  {
    path: '/projects/:project_ID',
    name:'project-detail',
    component:Layout,
    children:[
      {
        path:'',
        component:ProjectDetailView,
        props:true
      }
    ],
    props:true
  },
  {
    path:'/data',
    name:'data',
    component:Layout,
    children:[
      {
        path:'',
        component:DataDatailView
      }
    ]
  },
  {
    path: '/courses',
    name: 'courses',
    component:Layout,
    children:[
      {
        path:'',
        component:CourseIndexView,
        props:true
      }
    ]
  },
  {
    path: '/courses/:course_ID',
    name:'course-detail',
    component:Layout,
    children:[
      {
        path:'',
        component:CourseInfoView,
        props:true
      }
    ],
    props:true
  },
  {
    path:'/member',
    name:'member',
    component:Layout,
    children:[
      {
        path:'',
        component:MemberView
      }
    ]
  },
  {
    path:'/about',
    name:'about',
    component:Layout,
    children:[
      {
        path:'',
        component:AboutView
      }
    ]
  },
  {
    path:'/conference',
    name:'conference',
    component:Layout,
    children:[
      {
        path:'',
        component:ConferenceView
      }
    ]
  },
  {
    path:'/update',
    name:'update',
    component:Layout,
    children:[
      {
        path:'',
        component:UpdateView
      }
    ]
  },
  {
    path:'/search',
    name:'search',
    component:Layout,
    children:[
      {
        path:'',
        component:SearchView
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
