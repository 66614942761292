import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","justify-content":"space-between","margin-top":"5vh"} }
const _hoisted_2 = {
  key: 0,
  class: "result-list"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 1,
  class: "result-list"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["href"]

import { onMounted, ref, nextTick } from 'vue';
import axios from 'axios';
import type { TabsPaneContext,TabPaneName } from 'element-plus'
//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchView',
  setup(__props) {

const user_input = ref('')
const activeName = ref('first')

const project_result = ref<Array<{"project_ID":number,"project_name":string}>>([]);
const subproject_result = ref<Array<{"project_ID":number,"project_name":string,"subproject_ID":number,"subproject_name":string,"subproject_subname":string}>>([])
const data_result = ref<Array<{"data_ID":number,"data_name":string}>>([]);

const search = async() => {
    console.log(user_input.value);
    if(user_input.value.length==0)return;
    const response = (await axios.get(BaseUrl + '/api/f/search?user_input='+user_input.value)).data;
    project_result.value = response[0];
    const regex = new RegExp(`(${user_input.value})`, 'gi');
    project_result.value = project_result.value.map(item => {
        return {
            ...item,
            project_name: item.project_name.replace(regex, '<b>$1</b>')
        }
    })
    subproject_result.value = response[1];
    subproject_result.value = subproject_result.value.map(item => {
        return {
            ...item,
            project_name: item.project_name.replace(regex, '<b>$1</b>'),
            subproject_name: item.subproject_name.replace(regex, '<b>$1</b>'),
            subproject_subname: item.subproject_subname.replace(regex, '<b>$1</b>')
        }
    })
    data_result.value = response[2];
    data_result.value = data_result.value.map(item => {
        return {
            ...item,
            data_name: item.data_name.replace(regex, '<b>$1</b>')
        }
    })
    console.log(project_result.value, subproject_result.value,data_result.value);
    activeName.value = "first";
}

const handleClick = (tab: TabsPaneContext, event: Event) => {
    activeName.value = tab.paneName?.toString()||"first";
  console.log(tab.paneName, activeName.value);
}

onMounted(() => {
    document.title="北京城市实验室-搜索"
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_divider = _resolveComponent("el-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "search-title" }, "Search", -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", { class: "tip" }, "ENTER YOUR KEYWORDS", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_input, {
        modelValue: user_input.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((user_input).value = $event)),
        style: {"width":"70%","border":"1px solid #DDD","background":"#F9F9F9"},
        placeholder: "Please input"
      }, null, 8, ["modelValue"]),
      _createElementVNode("button", {
        class: "button",
        onClick: search
      }, "SEARCH")
    ]),
    _createVNode(_component_el_tabs, {
      modelValue: activeName.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((activeName).value = $event)),
      class: "tabs",
      onTabClick: handleClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "Project",
          name: "first"
        }),
        _createVNode(_component_el_tab_pane, {
          label: "Data",
          name: "second"
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (activeName.value==`first` && (project_result.value||subproject_result.value))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(project_result.value, (project, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "item-line"
            }, [
              _createVNode(_component_el_divider),
              _createElementVNode("p", {
                class: "title",
                innerHTML: `Projects | `+project.project_name
              }, null, 8, _hoisted_3),
              _createElementVNode("a", {
                class: "link",
                href: `/projects/`+project.project_ID
              }, " https://www.beijingcitylab.org/projects/" + _toDisplayString(project.project_ID), 9, _hoisted_4)
            ]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subproject_result.value, (subproject, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "item-line"
            }, [
              _createVNode(_component_el_divider),
              _createElementVNode("p", {
                class: "title",
                innerHTML: `Projects | `+subproject.project_name
              }, null, 8, _hoisted_5),
              _createElementVNode("p", {
                class: "subtitle",
                innerHTML: `subproject | `+subproject.subproject_name,
                x: ""
              }, null, 8, _hoisted_6),
              (subproject.subproject_subname)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "thirdtitle",
                    innerHTML: `(subtitle | `+subproject.subproject_subname+`)`
                  }, null, 8, _hoisted_7))
                : _createCommentVNode("", true),
              _createElementVNode("a", {
                class: "link",
                href: `/projects/`+subproject.project_ID+`#project-index-`+subproject.subproject_ID
              }, " https://www.beijingcitylab.org/projects/" + _toDisplayString(subproject.project_ID) + "#project-index-" + _toDisplayString(subproject.subproject_ID), 9, _hoisted_8)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (activeName.value==`second` && data_result.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data_result.value, (data, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "item-line"
            }, [
              _createVNode(_component_el_divider),
              _createElementVNode("p", {
                class: "title",
                innerHTML: `Data | `+data.data_name
              }, null, 8, _hoisted_10),
              _createElementVNode("a", {
                class: "link",
                href: `/data#data-index-`+data.data_ID
              }, " https://www.beijingcitylab.org/data#data-index-" + _toDisplayString(data.data_ID), 9, _hoisted_11)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})