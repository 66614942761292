import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: {"color":"#000","font-family":"Arial","font-size":"36px","font-style":"italic","font-weight":"700","line-height":"26px","letter-spacing":"0.9px"} }
const _hoisted_2 = { class: "member-list" }
const _hoisted_3 = {
  key: 0,
  style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"26px","letter-spacing":"0.5px"}
}

import { onMounted, ref, nextTick } from 'vue';
import axios from 'axios';
import MemberList from '@/components/MemberList.vue';
interface Member {
  name: string;
  memberDescribe: string;
  mail: string;
  personalPage: string;
  major: string;
  imageLink: string;
}

interface MemberGroup {
  second_level: string;
  member_list: Member[];
}

interface MemberSet {
  first_level: string;
  member_groups: MemberGroup[];
}
//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";

export default /*@__PURE__*/_defineComponent({
  __name: 'MemberView',
  setup(__props) {

const response_data = ref<MemberSet[]>([]);
onMounted(async () => {
    document.title="北京城市实验室-成员"
    const response = await axios.get(BaseUrl + '/api/f/all_member');
    response_data.value = response.data;
    console.log(response.data);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(response_data.value, (member_set) => {
    return (_openBlock(), _createElementBlock("div", {
      key: member_set.first_level,
      class: "member-group"
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(member_set.first_level), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(member_set.member_groups, (member_group) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (member_group.second_level)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(member_group.second_level), 1))
            : _createCommentVNode("", true),
          _createVNode(MemberList, {
            items: member_group.member_list
          }, null, 8, ["items"])
        ]))
      }), 256))
    ]))
  }), 128))
}
}

})