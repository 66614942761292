import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "images-container" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "images-container" }
const _hoisted_5 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","align-items":"flex-start","flex":"1 0 0","align-self":"stretch"}
}
const _hoisted_6 = { style: {"display":"flex","justify-content":"space-between","align-items":"center","align-self":"stretch","padding":"0px","margin-bottom":"0"} }
const _hoisted_7 = { style: {"margin":"0","color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal"} }
const _hoisted_8 = { style: {"display":"flex","padding":"0px","justify-content":"flex-end","align-items":"center","gap":"10px","align-self":"stretch"} }
const _hoisted_9 = { style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400"} }
const _hoisted_10 = ["href"]

import { onMounted, ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router'

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080";
const sc_logo = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/about/logo";

export default /*@__PURE__*/_defineComponent({
  __name: 'AboutView',
  setup(__props) {

const router = useRouter();
const introduction = ref("");
const journal_data = ref<Array<{ journal_uuid: string,journal_name: string, journal_cover: string, journal_link: string }>>([]);
const conference_data = ref([]);
onMounted(async () => {
  document.title="北京城市实验室-关于"
  const intro_response = await axios.get(BaseUrl + "/api/f/update");
  introduction.value = intro_response.data.introduction;
  introduction.value = introduction.value
    .split('\n')
    .map(paragraph => `<p style="color: #000;text-align: justify;font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;
    line-height: 24px; /* 150% */letter-spacing: 0.4px;margin-bottom: 32px;">${paragraph}</p>`)
    .join('');

  const journal_response = await axios.get(BaseUrl + "/api/f/about/journal");
  journal_data.value = journal_response.data.map((item: {uuid: string, journalLink: any; journalCover: any; journalName: any; }) => ({
    journal_name: item.journalName,
    journal_cover: item.journalCover,
    journal_link: item.journalLink,
    journal_uuid:item.uuid
  }));
  const conference_response = await axios.get(BaseUrl+"/api/f/about/conference/all");
  conference_data.value = conference_response.data;
  console.log(conference_data.value);
})

const journal_files = ref([]);
const selected_journal_name = ref("");
const file_table = ref(false)
const download_journal_files = async (journal_uuid,journal_name)=>{
  console.log(journal_uuid)
  journal_files.value = (await axios.get(BaseUrl+"/api/f/about/journal/file?journal_uuid="+journal_uuid)).data;
  file_table.value = true;
  selected_journal_name.value = journal_name;
}

const handleClose = (done: () => void) => {
    file_table.value = false; // 显式地关闭抽屉
    done();
}

const goToConferencePage = (conferenceName,uuid,conferenceSecondTitle)=>{
  console.log(conferenceName,uuid);
  router.push({
    path:'/conference',
    query:{
      conference_name:conferenceName,
      conference_uuid:uuid,
      conference_second_title:conferenceSecondTitle
    }
  })
}

return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("p", { class: "title" }, " BCL-Introduction ", -1)),
    _createElementVNode("div", { innerHTML: introduction.value }, null, 8, _hoisted_1),
    _cache[5] || (_cache[5] = _createElementVNode("p", {
      class: "title",
      style: {"margin-bottom":"50px"}
    }, " Supporters & Collaborators ", -1)),
    _createElementVNode("img", {
      style: {"width":"100%","height":"auto"},
      src: sc_logo
    }),
    _cache[6] || (_cache[6] = _createElementVNode("p", { class: "title" }, " Journals ", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(journal_data.value, (journal, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "image-container",
          key: index
        }, [
          _createElementVNode("a", {
            href: journal.journal_link,
            target: "_blank",
            rel: "noopener noreferrer"
          }, [
            _createVNode(_component_el_image, {
              style: {"width":"19.3vw","height":"26.2vw"},
              src: journal.journal_cover,
              fit: "scale-down"
            }, null, 8, ["src"])
          ], 8, _hoisted_3),
          _createVNode(_component_el_text, {
            truncated: "",
            style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"26px","letter-spacing":"0.5px","margin":"0","width":"19.3vw"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(journal.journal_name), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_el_button, {
            style: {"color":"#000","font-family":"Arial","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"24px","letter-spacing":"0.3px","padding":"0"},
            onClick: ($event: any) => (download_journal_files(journal.journal_uuid, journal.journal_name)),
            text: ""
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" more> ")
            ])),
            _: 2
          }, 1032, ["onClick"])
        ]))
      }), 128))
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("p", { class: "title" }, " conferences ", -1)),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conference_data.value, (conference, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "image-container",
          key: index
        }, [
          _createVNode(_component_el_image, {
            style: {"width":"19.3vw","height":"13.2vw"},
            src: conference.conferenceCover,
            fit: "scale-down",
            onClick: ($event: any) => (goToConferencePage(conference.conferenceName,conference.uuid,conference.conferenceSecondTitle))
          }, null, 8, ["src", "onClick"]),
          _createVNode(_component_el_text, {
            truncated: "",
            onClick: ($event: any) => (goToConferencePage(conference.conferenceName,conference.uuid,conference.conferenceSecondTitle)),
            style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"26px","letter-spacing":"0.5px","margin":"0","width":"19.3vw"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(conference.conferenceName), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]))
      }), 128))
    ]),
    _createVNode(_component_el_drawer, {
      modelValue: file_table.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((file_table).value = $event)),
      direction: "rtl",
      size: "30%",
      "before-close": handleClose,
      style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"normal"}
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", null, [
          _cache[2] || (_cache[2] = _createTextVNode(" Files from ")),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(selected_journal_name.value), 1)
        ])
      ]),
      default: _withCtx(() => [
        (journal_files.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(journal_files.value, (file, file_index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: file_index,
                  style: {"margin":"0","display":"flex","flex-direction":"column","align-items":"flex-start","align-self":"stretch"}
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_el_tooltip, {
                      class: "box-item",
                      effect: "dark",
                      content: /\.[a-zA-Z0-9]+$/.test(file.journalFileName)
                        ? file.journalFileName
                        : decodeURIComponent(file.journalFileLink.substring(file.journalFileLink.lastIndexOf('/') + 1)),
                      placement: "left"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_text, {
                          style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal","margin-top":"5px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","width":"18vw"},
                          truncated: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(/\.[a-zA-Z0-9]+$/.test(file.journalFileName)
                                    ? file.journalFileName
                                    :
                                    decodeURIComponent(file.journalFileLink.substring(file.journalFileLink.lastIndexOf('/')
                                        + 1))), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(file.journalFileSize), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("p", _hoisted_9, [
                      _createElementVNode("a", {
                        href: file.journalFileLink,
                        style: {"color":"inherit"}
                      }, "Download", 8, _hoisted_10)
                    ])
                  ]),
                  _createVNode(_component_el_divider, { style: {"margin":"3px"} })
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})