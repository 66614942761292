<template>
    <div class="images-container">
        <div class="image-container" v-for="(image, index) in images" :key="index" >
            <router-link :to="`/projects/${image.project_ID}`">
                <el-image style="width: 10.4vw;height: 10.4vw;" :src="image.url" fit="scale-down" />
            </router-link>
            <router-link :to="`/projects/${image.project_ID}`">
                <div class = "mask"></div>
            </router-link>
            <div class="overlay-text">{{ image.text }}</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';



//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const images = ref<Array<{ url: string; text: string,project_ID: string }>>([]);
const router = useRouter();
const fetchImages = async () => {
    try {
         const response = await axios.get(BaseUrl+ '/api/f/projects/all_projects');
        
        images.value = response.data.map((item: { project_bg_image_link: any; project_ID: string; project_name: string; }) => ({
            url: "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/project/"+item.project_name+"/cover",
            text: (parseInt(item.project_ID)+1).toString() + "-" + item.project_name,
            project_ID: (parseInt(item.project_ID)+1).toString()
        }));
    } catch (error) {
        console.error('Error fetching images:', error);
    }
    images.value.reverse();
};

onMounted(() => {
    document.title="北京城市实验室-项目列表"
    fetchImages();
});

</script>

<style scoped>
.images-container {
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 1.6vw;
    justify-content: flex-start;
    width: 100%;
}

.image-container {
    position: relative;
}

.overlay-text {
    position: absolute;
    width: 100%;
    transform: translateY(50%);
    bottom:5vw;
    background-color: rgba(255, 255, 255, 0.72);
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 162.5% */
    letter-spacing: 0.4px;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(232, 232, 232, 0.51);
}
</style>
