import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","align-items":"flex-start","flex":"1 0 0","align-self":"stretch"}
}
const _hoisted_4 = { style: {"display":"flex","justify-content":"space-between","align-items":"center","align-self":"stretch","padding":"0px","margin-bottom":"0"} }
const _hoisted_5 = { style: {"margin":"0","color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal"} }
const _hoisted_6 = { style: {"display":"flex","padding":"0px","justify-content":"flex-end","align-items":"center","gap":"10px","align-self":"stretch"} }
const _hoisted_7 = { style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400"} }
const _hoisted_8 = ["href"]

import { onMounted, ref,nextTick } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router'
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";


export default /*@__PURE__*/_defineComponent({
  __name: 'LongyingView',
  setup(__props) {

const quill_list = ref<Array<Quill>>([]);
const data_detail_list = ref([]);

const file_table = ref(false)
const file_list = ref([]);
onMounted(async () => {
    document.title="北京城市实验室-龙瀛"
    const response = await axios.get(BaseUrl +"/api/f/longying");
    data_detail_list.value = response.data;
    nextTick(() => {
        for (let index = 0; index < data_detail_list.value.length; index++) {
            const viewerId = `#viewer${index}`;
            const readonlyquill = new Quill(viewerId, {
                theme: 'snow',
                readOnly: true,
                modules: {
                    toolbar: false,
                }
            });
            readonlyquill.root.innerHTML = data_detail_list.value[index].content;
            quill_list.value.push(readonlyquill);
        }
    });

    file_list.value = (await axios.get(BaseUrl +"/api/f/longying/file")).data;
    console.log(file_list.value)
})

const handleClose = (done: () => void) => {
    file_table.value = false; // 显式地关闭抽屉
    done();
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[5] || (_cache[5] = _createElementVNode("p", { style: {"color":"#000","text-align":"center","font-family":"Arial","font-size":"36px","font-style":"normal","font-weight":"700","line-height":"24px","letter-spacing":"0.9px","align-items":"center"} }, " Dr Ying Long 龙瀛 ", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data_detail_list.value, (data, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(data.title), 1),
        _createElementVNode("div", {
          id: 'viewer' + index,
          class: "richtext"
        }, null, 8, _hoisted_2),
        (data.title=='About me and my lab')
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              class: "download-bottom",
              onClick: _cache[0] || (_cache[0] = () => {file_table.value = true })
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Download")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_divider, { style: {"margin":"3px"} })
      ]))
    }), 128)),
    _createVNode(_component_el_drawer, {
      modelValue: file_table.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((file_table).value = $event)),
      direction: "rtl",
      size: "30%",
      "before-close": handleClose,
      style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"normal"}
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", null, [
          _cache[3] || (_cache[3] = _createTextVNode(" Files from ")),
          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.selected_journal_name), 1)
        ])
      ]),
      default: _withCtx(() => [
        (file_list.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(file_list.value, (file, file_index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: file_index,
                  style: {"margin":"0","display":"flex","flex-direction":"column","align-items":"flex-start","align-self":"stretch"}
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_el_tooltip, {
                      class: "box-item",
                      effect: "dark",
                      content: /\.[a-zA-Z0-9]+$/.test(file.fileName)
                        ? file.fileName
                        : decodeURIComponent(file.fileLink.substring(file.fileLink.lastIndexOf('/') + 1)),
                      placement: "left"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_text, {
                          style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal","margin-top":"5px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","width":"18vw"},
                          truncated: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(/\.[a-zA-Z0-9]+$/.test(file.fileName)
                                    ? file.fileName
                                    :
                                    decodeURIComponent(file.fileLink.substring(file.fileLink.lastIndexOf('/')
                                        + 1))), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(file.fileSize), 1)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", _hoisted_7, [
                      _createElementVNode("a", {
                        href: file.fileLink,
                        style: {"color":"inherit"}
                      }, "Download", 8, _hoisted_8)
                    ])
                  ]),
                  _createVNode(_component_el_divider, { style: {"margin":"3px"} })
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})