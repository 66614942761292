import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = ["src"]

import { onMounted, ref, nextTick } from 'vue';
import axios from 'axios';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateView',
  setup(__props) {

const title = ref("");
const content = ref("");
const image_link = ref("");
onMounted(async () => {
    document.title="北京城市实验室-更新"
    const response = (await axios.get(BaseUrl + "/api/f/update")).data;
    title.value = response.title;
    content.value = response.content;
    image_link.value = response.imageLink;
    console.log(response)
})

return (_ctx: any,_cache: any) => {
  const _component_P = _resolveComponent("P")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(title.value), 1),
    _createVNode(_component_P, { class: "content" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(content.value), 1)
      ]),
      _: 1
    }),
    _createElementVNode("img", {
      src: image_link.value,
      style: {"display":"block","margin":"0 auto"}
    }, null, 8, _hoisted_2)
  ], 64))
}
}

})