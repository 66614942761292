import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","padding-bottom":"2vh","gap":"10px"} }
const _hoisted_3 = { style: {"color":"#000","font-family":"Arial","font-size":"36px","font-style":"italic","font-weight":"700","line-height":"26px","letter-spacing":"0.9px"} }
const _hoisted_4 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","align-items":"center","flex":"1 0 0"}
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","align-self":"stretch"} }
const _hoisted_7 = { style: {"display":"flex","width":"50%","align-items":"flex-start","gap":"10px","color":"#000","margin":"0","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"26px","letter-spacing":"0.5px"} }
const _hoisted_8 = { style: {"display":"flex","justify-content":"flex-end","width":"100%"} }
const _hoisted_9 = { style: {"display":"flex","justify-content":"space-between","align-items":"stretch"} }
const _hoisted_10 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"1vh","flex":"1 0 0","margin-right":"1%","box-sizing":"border-box"} }
const _hoisted_11 = { style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"26px","letter-spacing":"0.5px","margin":"0"} }
const _hoisted_12 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"1vh","flex":"1 0 0","margin-left":"1%","box-sizing":"border-box"} }
const _hoisted_13 = { style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"26px","letter-spacing":"0.5px","margin":"0"} }
const _hoisted_14 = { style: {"color":"#000","font-family":"Arial","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"26px","letter-spacing":"0.4px"} }
const _hoisted_15 = { style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"26px","letter-spacing":"0.5px"} }
const _hoisted_16 = { style: {"color":"#000","font-family":"Arial","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"26px","letter-spacing":"0.4px"} }

import { onMounted, ref, nextTick } from 'vue';
import { ElMessage } from 'element-plus'
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios, { toFormData } from 'axios';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"

export default /*@__PURE__*/_defineComponent({
  __name: 'CourseInfoView',
  props: {
    course_ID: {}
  },
  setup(__props: any) {

const defaultImage = ref("https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg");
const props = __props;
const title = ref("");
const video_link = ref("");
const textbook_imagelink = ref("");
const textbook_intro = ref("")
const textbook_file = ref("");
const mooc_imagelink = ref("");
const mooc_describe_text = ref("");
const outline_imagelink = ref("");
const describe_text = ref("");


const title1 = ref("教材");
const title2 = ref("MOOC");
const title3 = ref("授课计划");
const title4 = ref("课程简介");

const readonlyquill = ref<Quill>();
const error = ref(false);

const handleDownload = () => {
    if (textbook_file.value) {
        // 文件存在，执行下载
        const link = document.createElement('a');
        link.href = textbook_file.value;
        link.click();
    } else {
        ElMessage({
            message: '要下载的文件不存在',
            type: 'warning',
        })
    }
};

onMounted(async () => {
    document.title="北京城市实验室-课程详情"
    try {
        const response = await axios.get(BaseUrl + "/api/f/course/info?index=" + props.course_ID);
        console.log(response);
        title.value = response.data.index + " " + response.data.name;
        const courseContents = response.data.courseContents;
        video_link.value = courseContents[0].link;
        if(!video_link.value.endsWith('mp4') && !video_link.value.endsWith('avi') && !video_link.value.endsWith('ogg')&& !video_link.value.endsWith('webm')){
            video_link.value = "";
        }
        textbook_imagelink.value = courseContents[1].link;
        
        var tmp = courseContents[1].content;
        console.log("tmp1",tmp);
        if (tmp==null) tmp = "暂无内容"
        tmp = tmp.split('##');
        console.log("tmp2",tmp);
        if(tmp.length>=2){
            title1.value=tmp[1];
            tmp.splice(1,1);
            textbook_intro.value = tmp.join('');
        }
        else{
            title1.value = "教材";
            textbook_intro.value = courseContents[1].content;
        }

        mooc_imagelink.value = courseContents[2].link;
        mooc_describe_text.value = courseContents[2].content;

        tmp = mooc_describe_text.value;
        if (tmp==null) tmp = "暂无内容";
        tmp = tmp.split('##');
        if(tmp.length>=2){
            title2.value=tmp[1];
            tmp.splice(1,1);
            mooc_describe_text.value = tmp.join('');
        }
        else{
            title2.value = "MOOC";
        }

        tmp = mooc_describe_text.value;
        if (tmp==null) tmp = "暂无内容";
         tmp = tmp.split('$$');
        if(tmp.length>=2){
            title3.value=tmp[1];
            tmp.splice(1,1);
            mooc_describe_text.value = tmp.join('');
        }
        else{
            title3.value = "授课计划";
        }

        outline_imagelink.value = courseContents[3].link;
        //describe_text.value = courseContents[4].content;

        tmp = courseContents[4].content;
        if (tmp==null) tmp = "暂无内容";
        tmp = tmp.split('##');
        if(tmp.length>=2){
            title4.value=tmp[1];
            tmp.splice(1,1);
            describe_text.value = tmp.join('');
        }
        else{
            title4.value = "课程简介";
            describe_text.value = courseContents[4].content;
        }
        textbook_file.value = courseContents[5].link;
        readonlyquill.value = new Quill(`#viewer${1}`, {
            theme: 'snow',
            readOnly: true,
            modules: {
                toolbar: false,
            }
        });
        readonlyquill.value.root.innerHTML = textbook_intro.value;
        error.value = false;
    } catch (err: any) {
        error.value = true;
        console.log(err);
    }

});

return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    (error.value)
      ? (_openBlock(), _createBlock(_component_el_image, {
          key: 0,
          src: defaultImage.value,
          alt: "404 Not Found",
          fit: "scale-down"
        }, null, 8, ["src"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(title.value), 1)
          ]),
          (video_link.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("video", {
                  src: video_link.value,
                  controls: "",
                  preload: "auto",
                  style: {"width":"100%","height":"auto","margin-bottom":"1vh"}
                }, null, 8, _hoisted_5),
                _createVNode(_component_el_divider)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(title1.value), 1)
          ]),
          _createVNode(_component_el_row, { class: "course-row" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, {
                    class: "course-image",
                    src: textbook_imagelink.value || defaultImage.value,
                    "preview-src-list": [textbook_imagelink.value || defaultImage.value],
                    fit: "scale-down"
                  }, null, 8, ["src", "preview-src-list"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 16,
                class: "course-details"
              }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("div", {
                    id: 'viewer1',
                    class: "richtext"
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_el_button, {
                      class: "download-bottom",
                      onClick: handleDownload
                    }, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createTextVNode(" Download")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_divider),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(title2.value), 1),
              _createVNode(_component_el_image, {
                style: {"margin":"0","width":"100%","height":"30vh"},
                src: mooc_imagelink.value || defaultImage.value,
                "preview-src-list": [mooc_imagelink.value || defaultImage.value],
                fit: "scale-down"
              }, null, 8, ["src", "preview-src-list"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", _hoisted_13, _toDisplayString(title3.value), 1),
              _createVNode(_component_el_image, {
                style: {"margin":"0","width":"100%","height":"30vh"},
                src: outline_imagelink.value || defaultImage.value,
                "preview-src-list": [outline_imagelink.value || defaultImage.value],
                fit: "scale-down"
              }, null, 8, ["src", "preview-src-list"])
            ])
          ]),
          _createElementVNode("p", _hoisted_14, _toDisplayString(mooc_describe_text.value), 1),
          _createVNode(_component_el_divider),
          _createElementVNode("p", _hoisted_15, _toDisplayString(title4.value), 1),
          _createElementVNode("p", _hoisted_16, _toDisplayString(describe_text.value), 1)
        ]))
  ]))
}
}

})