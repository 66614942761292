<template>
    <div>
        <el-image v-if="error" :src=defaultImage alt="404 Not Found"  fit="scale-down"/>
        <div v-else>
            <div style="display: flex;align-items: center;padding-bottom: 2vh;  gap: 10px;">
                <p
                    style="color: #000;font-family: Arial;font-size: 36px;font-style: italic;font-weight: 700;line-height: 26px; /* 72.222% */letter-spacing: 0.9px;">
                    {{ title }}
                </p>
            </div>

            <div v-if="video_link" style="display: flex;flex-direction: column;align-items: center;flex:1 0 0;">
                <video :src="video_link" controls preload="auto"
                    style="width: 100%;height:auto;margin-bottom: 1vh;"></video>
                <el-divider></el-divider>
            </div>

            <div style="display: flex;flex-direction: column;align-items: flex-start;align-self: stretch;">
                <p
                    style="display: flex;width: 50%;align-items: flex-start;gap: 10px;color: #000;margin: 0;
                font-family: Arial;font-size: 20px;font-style: italic;font-weight: 700;line-height: 26px; /* 130% */letter-spacing: 0.5px;">
                    {{ title1 }}
                </p>
            </div>
            <el-row class="course-row">
                <el-col :span="8">
                    <el-image class="course-image" :src="textbook_imagelink || defaultImage"
                        :preview-src-list="[textbook_imagelink || defaultImage]" fit="scale-down" />
                </el-col>
                <el-col :span="16" class="course-details">
                    <div :id="'viewer1'" class="richtext"></div>
                    <div style="display: flex;justify-content:flex-end;width: 100%; ">
                        <el-button class="download-bottom" @click="handleDownload">
                            Download</el-button>
                    </div>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between; align-items: stretch;">
                <div
                    style="display: flex; flex-direction: column; align-items: flex-start; gap: 1vh; flex: 1 0 0; margin-right: 1%; box-sizing: border-box;">
                    <p
                        style="color: #000; font-family: Arial; font-size: 20px; font-style: italic; font-weight: 700; line-height: 26px; letter-spacing: 0.5px; margin: 0;">
                        {{ title2 }}
                    </p>
                    <el-image style="margin: 0; width: 100%; height: 30vh;" :src="mooc_imagelink || defaultImage"
                        :preview-src-list="[mooc_imagelink || defaultImage]" fit="scale-down" />
                </div>
                <div
                    style="display: flex; flex-direction: column; align-items: flex-start; gap: 1vh; flex: 1 0 0; margin-left: 1%; box-sizing: border-box;">
                    <p
                        style="color: #000; font-family: Arial; font-size: 20px; font-style: italic; font-weight: 700; line-height: 26px; letter-spacing: 0.5px; margin: 0;">
                        {{ title3 }}
                    </p>
                    <el-image style="margin: 0; width: 100%; height: 30vh;" :src="outline_imagelink || defaultImage"
                        :preview-src-list="[outline_imagelink || defaultImage]" fit="scale-down" />
                </div>
            </div>
            <p style="color: #000;font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;line-height: 26px; /* 162.5% */letter-spacing: 0.4px;">
                {{ mooc_describe_text }}
            </p>
            <el-divider></el-divider>
            <p style="color: #000;font-family: Arial;font-size: 20px;font-style: italic;font-weight: 700;line-height: 26px; /* 130% */letter-spacing: 0.5px;">
                {{ title4 }}
            </p>
            <p style="color: #000;font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;line-height: 26px; /* 162.5% */letter-spacing: 0.4px;">
                {{ describe_text }}
            </p>

        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, nextTick, defineProps } from 'vue';
import { ElMessage } from 'element-plus'
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios, { toFormData } from 'axios';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = ref("https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg");
const props = defineProps<{
    course_ID: string;
}>();
const title = ref("");
const video_link = ref("");
const textbook_imagelink = ref("");
const textbook_intro = ref("")
const textbook_file = ref("");
const mooc_imagelink = ref("");
const mooc_describe_text = ref("");
const outline_imagelink = ref("");
const describe_text = ref("");


const title1 = ref("教材");
const title2 = ref("MOOC");
const title3 = ref("授课计划");
const title4 = ref("课程简介");

const readonlyquill = ref<Quill>();
const error = ref(false);

const handleDownload = () => {
    if (textbook_file.value) {
        // 文件存在，执行下载
        const link = document.createElement('a');
        link.href = textbook_file.value;
        link.click();
    } else {
        ElMessage({
            message: '要下载的文件不存在',
            type: 'warning',
        })
    }
};

onMounted(async () => {
    document.title="北京城市实验室-课程详情"
    try {
        const response = await axios.get(BaseUrl + "/api/f/course/info?index=" + props.course_ID);
        console.log(response);
        title.value = response.data.index + " " + response.data.name;
        const courseContents = response.data.courseContents;
        video_link.value = courseContents[0].link;
        if(!video_link.value.endsWith('mp4') && !video_link.value.endsWith('avi') && !video_link.value.endsWith('ogg')&& !video_link.value.endsWith('webm')){
            video_link.value = "";
        }
        textbook_imagelink.value = courseContents[1].link;
        
        var tmp = courseContents[1].content;
        console.log("tmp1",tmp);
        if (tmp==null) tmp = "暂无内容"
        tmp = tmp.split('##');
        console.log("tmp2",tmp);
        if(tmp.length>=2){
            title1.value=tmp[1];
            tmp.splice(1,1);
            textbook_intro.value = tmp.join('');
        }
        else{
            title1.value = "教材";
            textbook_intro.value = courseContents[1].content;
        }

        mooc_imagelink.value = courseContents[2].link;
        mooc_describe_text.value = courseContents[2].content;

        tmp = mooc_describe_text.value;
        if (tmp==null) tmp = "暂无内容";
        tmp = tmp.split('##');
        if(tmp.length>=2){
            title2.value=tmp[1];
            tmp.splice(1,1);
            mooc_describe_text.value = tmp.join('');
        }
        else{
            title2.value = "MOOC";
        }

        tmp = mooc_describe_text.value;
        if (tmp==null) tmp = "暂无内容";
         tmp = tmp.split('$$');
        if(tmp.length>=2){
            title3.value=tmp[1];
            tmp.splice(1,1);
            mooc_describe_text.value = tmp.join('');
        }
        else{
            title3.value = "授课计划";
        }

        outline_imagelink.value = courseContents[3].link;
        //describe_text.value = courseContents[4].content;

        tmp = courseContents[4].content;
        if (tmp==null) tmp = "暂无内容";
        tmp = tmp.split('##');
        if(tmp.length>=2){
            title4.value=tmp[1];
            tmp.splice(1,1);
            describe_text.value = tmp.join('');
        }
        else{
            title4.value = "课程简介";
            describe_text.value = courseContents[4].content;
        }
        textbook_file.value = courseContents[5].link;
        readonlyquill.value = new Quill(`#viewer${1}`, {
            theme: 'snow',
            readOnly: true,
            modules: {
                toolbar: false,
            }
        });
        readonlyquill.value.root.innerHTML = textbook_intro.value;
        error.value = false;
    } catch (err: any) {
        error.value = true;
        console.log(err);
    }

});
</script>

<style scoped>
.course-row {
    display: flex;
    align-items: stretch;
    margin-top: 20px;
    margin-bottom: 20px;
}

.course-image {
    width: 18vw;
    height: 27vw;
}

.index-box {
    position: absolute;
    top: 8%;
    /* 将顶部位置设置为容器高度的50% */
    left: 1%;
    /* 将左侧位置设置为容器宽度的50% */
    transform: translate(-50%, -50%);
    /* 使用变换将元素自身的中心对齐到父容器的中心 */
    background: rgba(0, 36, 128, 0.80);
    color: #FFF;
    font-family: Arial;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1px;
    padding: 0;
    text-align: center;
    width: 3vw;
    height: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.course-details {
    flex: 1;
    /* 可伸缩 */
    display: flex;
    justify-content: space-between;
    /* 在垂直方向上分布内容 */
    flex-direction: column;
    align-items: flex-start;
    height: 27vw;
    padding-left: 20px;
    /* 增加内容与图像之间的间距 */
    box-sizing: border-box;
    /* 确保内边距包含在总宽度内 */
}

.richtext {
    flex: 1;
    width: 100%;
    min-height: 0;
    overflow-y: auto;
    border: none !important;
    box-shadow: none !important;
    margin-top: 5px;
    margin-bottom: 10px;
}

.download-bottom {
    display: flex;
    height: 0.6vh;
    padding: 16px 32px;
    align-items: center;
    gap: 8px;
    background: var(--Base-Black-100, #222325);
    color: var(--Base-White-100, #FFF);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
}
</style>
