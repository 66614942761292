import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "item-table" }
const _hoisted_2 = { class: "person-data" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 1,
  style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"700","line-height":"normal","white-space":"nowrap!important","margin":"0","margin-bottom":"15px!important"}
}
const _hoisted_5 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","align-self":"stretch"} }
const _hoisted_6 = { style: {"display":"flex","align-items":"flex-start","gap":"5px"} }
const _hoisted_7 = { style: {"width":"18vw","color":"#000","font-family":"Inter","font-size":"14px","font-style":"normal","font-weight":"400","line-height":"normal","white-space":"normal","word-wrap":"break-word","margin":"0","margin-bottom":"5px!important"} }
const _hoisted_8 = { style: {"display":"flex","align-items":"center","gap":"5px"} }
const _hoisted_9 = { style: {"width":"18vw","color":"#000","font-family":"Inter","font-size":"14px","font-style":"normal","font-weight":"400","line-height":"normal","white-space":"normal","word-wrap":"break-word","margin":"0","margin-bottom":"5px!important"} }
const _hoisted_10 = { style: {"display":"flex","align-items":"center","gap":"5px"} }
const _hoisted_11 = { style: {"width":"18vw","color":"#000","font-family":"Inter","font-size":"14px","font-style":"normal","font-weight":"400","line-height":"normal","margin":"0"} }

import { computed } from 'vue'
// 定义组件接收的props类型
interface Member {
  name: string
  memberDescribe: string
  mail:string
  personalPage:string
  major:string
  imageLink:string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MemberList',
  props: {
    items: {}
  },
  setup(__props: any) {

const props = __props

// 将items按每两个分组
const groupedItems = computed(() => {
  const groups: Member[][] = []
  for (let i = 0; i < props.items.length; i += 2) {
    groups.push(props.items.slice(i, i + 2))
  }
  return groups
})

return (_ctx: any,_cache: any) => {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Aim = _resolveComponent("Aim")!
  const _component_Message = _resolveComponent("Message")!
  const _component_el_avatar = _resolveComponent("el-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupedItems.value, (item_row, index_row) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "item-row",
        key: index_row
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item_row, (item, itemIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "item",
            key: itemIndex
          }, [
            _createElementVNode("div", _hoisted_2, [
              (item.personalPage)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: item.personalPage,
                    target: "_blank",
                    style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"700","line-height":"normal","white-space":"nowrap!important","margin":"0","margin-bottom":"15px!important"}
                  }, _toDisplayString(item.name), 9, _hoisted_3))
                : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(item.name), 1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_icon, { style: {"width":"14px","height":"14px","padding-top":"3px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Avatar)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(item.memberDescribe), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_el_icon, { style: {"width":"14px","height":"14px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Aim)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_9, _toDisplayString(item.major), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_el_icon, { style: {"width":"14px","height":"14px","padding-top":"3px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Message)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_11, _toDisplayString(item.mail), 1)
                ])
              ])
            ]),
            _createVNode(_component_el_avatar, {
              style: {"width":"7vw","height":"7vw","background-color":"white","border-style":"solid","border-width":"2px","border-color":"rgba(31,31,31,0.3)"},
              src: item.imageLink,
              fit: "scale-down"
            }, null, 8, ["src"])
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}
}

})