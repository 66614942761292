<template>

    <p class="title">
        {{ title }}
    </p>
    <P class="content">
        {{ content }}
    </P>
    <img :src="image_link" style="display: block;margin: 0 auto;"/>
</template>

<script lang="ts" setup>
import { onMounted, ref, defineProps, nextTick } from 'vue';
import axios from 'axios';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";

const title = ref("");
const content = ref("");
const image_link = ref("");
onMounted(async () => {
    document.title="北京城市实验室-更新"
    const response = (await axios.get(BaseUrl + "/api/f/update")).data;
    title.value = response.title;
    content.value = response.content;
    image_link.value = response.imageLink;
    console.log(response)
})
</script>


<style scoped>
.title {
    color: #000;
    text-align: justify;
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.9px;
    align-items: center;
    text-align: center;
}

.content {
    color: #000;
    text-align: justify;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.4px;
}
</style>