<template>
<p class="search-title">Search</p>
<p class="tip">ENTER YOUR KEYWORDS</p>
<div style="display: flex;justify-content: space-between;margin-top: 5vh;">
    <el-input v-model="user_input" style="width: 70%;border: 1px solid #DDD;background: #F9F9F9;" placeholder="Please input" />
    <button class="button" @click="search">SEARCH</button>
</div>
<el-tabs v-model="activeName" class="tabs" @tab-click="handleClick">
    <el-tab-pane label="Project" name="first"></el-tab-pane>
    <el-tab-pane label="Data" name="second"></el-tab-pane>
  </el-tabs>
<div class = "result-list" v-if="activeName==`first` && (project_result||subproject_result)">
    <div v-for="(project,index) in project_result" :key="index" class = "item-line">
        <el-divider></el-divider>
        <p class = "title" v-html="`Projects | `+project.project_name"></p>

        <a class = "link" :href="`/projects/`+project.project_ID">
            https://www.beijingcitylab.org/projects/{{ project.project_ID }}
        </a>
    </div>

    <div v-for="(subproject,index) in subproject_result" :key="index" class = "item-line">
        <el-divider></el-divider>
        <p class = "title" v-html="`Projects | `+subproject.project_name"></p>
        <p class = "subtitle" v-html="`subproject | `+subproject.subproject_name" x></p>
        <p class = "thirdtitle" v-if="subproject.subproject_subname" v-html="`(subtitle | `+subproject.subproject_subname+`)`"></p>
        <a class = "link" :href="`/projects/`+subproject.project_ID+`#project-index-`+subproject.subproject_ID" >
            https://www.beijingcitylab.org/projects/{{ subproject.project_ID }}#project-index-{{ subproject.subproject_ID }}
        </a>
    </div>
</div>
<div class = "result-list" v-if="activeName==`second` && data_result">
    <div v-for="(data,index) in data_result" :key="index" class = "item-line">
        <el-divider></el-divider>
        <p class = "title" v-html="`Data | `+data.data_name"></p>

        <a class = "link" :href="`/data#data-index-`+data.data_ID">
            https://www.beijingcitylab.org/data#data-index-{{ data.data_ID }}
        </a>
    </div>
</div>
</template>

<script lang="ts" setup>
import { onMounted, ref, nextTick, defineProps } from 'vue';
import axios from 'axios';
import type { TabsPaneContext,TabPaneName } from 'element-plus'
//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"

const user_input = ref('')
const activeName = ref('first')

const project_result = ref<Array<{"project_ID":number,"project_name":string}>>([]);
const subproject_result = ref<Array<{"project_ID":number,"project_name":string,"subproject_ID":number,"subproject_name":string,"subproject_subname":string}>>([])
const data_result = ref<Array<{"data_ID":number,"data_name":string}>>([]);

const search = async() => {
    console.log(user_input.value);
    if(user_input.value.length==0)return;
    const response = (await axios.get(BaseUrl + '/api/f/search?user_input='+user_input.value)).data;
    project_result.value = response[0];
    const regex = new RegExp(`(${user_input.value})`, 'gi');
    project_result.value = project_result.value.map(item => {
        return {
            ...item,
            project_name: item.project_name.replace(regex, '<b>$1</b>')
        }
    })
    subproject_result.value = response[1];
    subproject_result.value = subproject_result.value.map(item => {
        return {
            ...item,
            project_name: item.project_name.replace(regex, '<b>$1</b>'),
            subproject_name: item.subproject_name.replace(regex, '<b>$1</b>'),
            subproject_subname: item.subproject_subname.replace(regex, '<b>$1</b>')
        }
    })
    data_result.value = response[2];
    data_result.value = data_result.value.map(item => {
        return {
            ...item,
            data_name: item.data_name.replace(regex, '<b>$1</b>')
        }
    })
    console.log(project_result.value, subproject_result.value,data_result.value);
    activeName.value = "first";
}

const handleClick = (tab: TabsPaneContext, event: Event) => {
    activeName.value = tab.paneName?.toString()||"first";
  console.log(tab.paneName, activeName.value);
}

onMounted(() => {
    document.title="北京城市实验室-搜索"
});
</script>

<style scoped>
.search-title{
    color: #000;
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 66.667% */
    letter-spacing: 0.9px;
}

.tip{
    color: #286DC0;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    letter-spacing: 0.5px;
    margin-top: 5vh;
}
.button{
    display: flex;
    width: 20%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    align-self: stretch;
    background: #286DC0;
    color: #FFF;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: 0.5px;
}

.tabs{
    margin-top: 3vh;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

.result-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.item-line{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.title{
    color: #000;
    font-family: Arial;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 100% */
    letter-spacing: 0.6px;
    margin-top: 0;
    margin-bottom: 1.5vh;
}

.subtitle{
    color: #000;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 100% */
    letter-spacing: 0.6px;
    margin-top: 0;
    margin-left: 2vw;
    margin-bottom: 1vh;
}

.thirdtitle{
    color: #000;
    font-family: Arial;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px; /* 100% */
    letter-spacing: 0.6px;
    margin-top: 0;
    margin-left: 2vw;
}

.link{
    color: #686662;
    font-family: Arial;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 160% */
    letter-spacing: 0.375px;
}
</style>