import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "title-frame" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","align-items":"flex-start","flex":"1 0 0","align-self":"stretch"}
}
const _hoisted_4 = { style: {"display":"flex","justify-content":"space-between","align-items":"center","align-self":"stretch","padding":"0px","margin-bottom":"0"} }
const _hoisted_5 = { style: {"margin":"0","color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal"} }
const _hoisted_6 = { style: {"display":"flex","padding":"0px","justify-content":"flex-end","align-items":"center","gap":"10px","align-self":"stretch"} }
const _hoisted_7 = { style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400"} }
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 0,
  class: "second-title"
}
const _hoisted_10 = { class: "title-frame" }

import { onMounted, ref, nextTick, watch } from 'vue';
import { useRoute } from 'vue-router'
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";

export default /*@__PURE__*/_defineComponent({
  __name: 'ConferenceView',
  setup(__props) {

const route = useRoute();
const conference_files = ref([]);
const file_table = ref(false);
const long_images = ref([]);
const images = ref([]);

const quill = ref<Quill>();

onMounted(async () => {
    document.title="北京城市实验室-年会"
    const response = await axios.get(BaseUrl + "/api/f/about/conference/info?conference_uuid=" + route.query.conference_uuid);
    console.log(response.data);
    conference_files.value = response.data[2];
    long_images.value = response.data[0];
    images.value = response.data[1];
    nextTick(() => {
        const readonlyquill = new Quill("#readonly", {
            theme: 'snow',
            readOnly: true,
            modules: {
                toolbar: false,
            }
        });
        readonlyquill.root.innerHTML = response.data[3].length > 0 ? response.data[3][0] : "";
        quill.value=readonlyquill;
    });
})


const handleClose = (done: () => void) => {
    file_table.value = false; // 显式地关闭抽屉
    done();
    selectedProject.value = null; // 清除选中的项目
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_drawer = _resolveComponent("el-drawer")!
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(route).query.conference_name), 1),
      _createVNode(_component_el_button, {
        class: "download-bottom",
        onClick: _cache[0] || (_cache[0] = () => { file_table.value = true })
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Download ")
        ])),
        _: 1
      }),
      _createVNode(_component_el_drawer, {
        modelValue: file_table.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((file_table).value = $event)),
        title: "Files",
        direction: "rtl",
        size: "30%",
        "before-close": handleClose,
        style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"normal"}
      }, {
        default: _withCtx(() => [
          (conference_files.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conference_files.value, (file, file_index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: file_index,
                    style: {"margin":"0","display":"flex","flex-direction":"column","align-items":"flex-start","align-self":"stretch"}
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_el_tooltip, {
                        class: "box-item",
                        effect: "dark",
                        content: /\.[a-zA-Z0-9]+$/.test(file.itemFileName)
                                ? file.itemFileName
                                : decodeURIComponent(file.itemFileLink.substring(file.itemFileLink.lastIndexOf('/') + 1)),
                        placement: "left"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_text, {
                            style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal","margin-top":"5px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","width":"18vw"},
                            truncated: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(/\.[a-zA-Z0-9]+$/.test(file.itemFileName)
                                        ? file.itemFileName
                                        :
                                        decodeURIComponent(file.itemFileLink.substring(file.itemFileLink.lastIndexOf('/')
                                            + 1))), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["content"]),
                      _createElementVNode("p", _hoisted_5, _toDisplayString(file.itemFileSize), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", _hoisted_7, [
                        _createElementVNode("a", {
                          href: file.itemFileLink,
                          style: {"color":"inherit"}
                        }, "Download", 8, _hoisted_8)
                      ])
                    ]),
                    _createVNode(_component_el_divider, { style: {"margin":"3px"} })
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    (_unref(route).query.conference_second_title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_unref(route).query.conference_second_title), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_el_divider),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(long_images.value, (long_image, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createVNode(_component_el_image, {
          src: long_image.itemImageLink,
          style: {"display":"block","margin":"0 auto"}
        }, null, 8, ["src"])
      ]))
    }), 128)),
    _createVNode(_component_el_divider),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_el_image, {
        style: {"width":"30vw","height":"25vh","margin":"0"},
        src: images.value.length > 0 ? images.value[0].itemImageLink : defaultImage,
        fit: "scale-down",
        "preview-src-list": images.value.map(img => img.itemImageLink)
      }, null, 8, ["src", "preview-src-list"]),
      _cache[3] || (_cache[3] = _createElementVNode("div", {
        id: "readonly",
        class: "richtext"
      }, null, -1))
    ])
  ], 64))
}
}

})