<template>
    <div class="title-frame">
        <p class="title">
            {{ route.query.conference_name }}
        </p>
        <el-button class="download-bottom" @click="() => { file_table = true }">
            Download
        </el-button>

        <el-drawer v-model="file_table" title="Files" direction="rtl" size="30%" :before-close="handleClose"
            style="color: #000;font-family: Arial;font-size: 20px;font-style: italic;font-weight: 700;line-height: normal;">
            <div v-if="conference_files"
                style="display: flex;flex-direction: column;align-items: flex-start;flex: 1 0 0;align-self: stretch;">
                <div v-for="(file, file_index) in conference_files" :key="file_index"
                    style="margin:0;display: flex;flex-direction: column;align-items: flex-start;align-self: stretch;">
                    <div
                        style="display: flex;justify-content: space-between;align-items: center;align-self: stretch;display: flex;padding: 0px;align-items: center;margin-bottom: 0;">
                        <el-tooltip class="box-item" effect="dark"
                            :content="/\.[a-zA-Z0-9]+$/.test(file.itemFileName)
                                ? file.itemFileName
                                : decodeURIComponent(file.itemFileLink.substring(file.itemFileLink.lastIndexOf('/') + 1))" placement="left">
                            <el-text style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;
                    margin-top: 5px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:18vw ;"
                                truncated>
                                {{
                                    /\.[a-zA-Z0-9]+$/.test(file.itemFileName)
                                        ? file.itemFileName
                                        :
                                        decodeURIComponent(file.itemFileLink.substring(file.itemFileLink.lastIndexOf('/')
                                            + 1))
                                }}
                            </el-text>
                        </el-tooltip>
                        <p
                            style="margin:0;color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;">
                            {{ file.itemFileSize }}
                        </p>
                    </div>
                    <div
                        style="display: flex;padding: 0px;justify-content: flex-end;align-items: center;gap: 10px;align-self: stretch;">
                        <p style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;">
                            <a :href="file.itemFileLink" style="color: inherit;">Download</a>
                        </p>
                    </div>
                    <el-divider style="margin: 3px;"></el-divider>
                </div>
            </div>
        </el-drawer>
    </div>
    <div v-if="route.query.conference_second_title" class="second-title">
        {{ route.query.conference_second_title }}
    </div>
    <el-divider/>
    <div v-for="(long_image, index) in long_images" :key="index">
        <el-image :src=long_image.itemImageLink style="display: block;margin: 0 auto;" />
    </div>
    <el-divider/>
    <div class="title-frame">
        <el-image style="width: 30vw;height:25vh;margin: 0;"
            :src="images.length > 0 ? images[0].itemImageLink : defaultImage" fit="scale-down"
            :preview-src-list="images.map(img => img.itemImageLink)" />
        <div id="readonly" class="richtext"></div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, nextTick, defineProps, watch } from 'vue';
import { useRoute } from 'vue-router'
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";
const route = useRoute();
const conference_files = ref([]);
const file_table = ref(false);
const long_images = ref([]);
const images = ref([]);

const quill = ref<Quill>();

onMounted(async () => {
    document.title="北京城市实验室-年会"
    const response = await axios.get(BaseUrl + "/api/f/about/conference/info?conference_uuid=" + route.query.conference_uuid);
    console.log(response.data);
    conference_files.value = response.data[2];
    long_images.value = response.data[0];
    images.value = response.data[1];
    nextTick(() => {
        const readonlyquill = new Quill("#readonly", {
            theme: 'snow',
            readOnly: true,
            modules: {
                toolbar: false,
            }
        });
        readonlyquill.root.innerHTML = response.data[3].length > 0 ? response.data[3][0] : "";
        quill.value=readonlyquill;
    });
})


const handleClose = (done: () => void) => {
    file_table.value = false; // 显式地关闭抽屉
    done();
    selectedProject.value = null; // 清除选中的项目
}
</script>
<style scoped>
.title-frame {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 10px;
}

.title {
margin-top: 5px;
    color: #000;
    text-align: justify;
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 66.667% */
    letter-spacing: 0.9px;
}

.second-title {
    margin-bottom: 15px;
    color: #000;
    text-align: justify;
    font-family: Arial;
    font-size: 30px;
    font-style: italic;
    font-weight: 700;
    line-height: 24px;
    /* 66.667% */
    letter-spacing: 0.9px;
}

.download-bottom {
    display: flex;
    height: 0.6vh;
    padding: 16px 32px;
    align-items: center;
    gap: 8px;
    background: var(--Base-Black-100, #222325);
    color: var(--Base-White-100, #FFF);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
}

.richtext {
    flex: 1;
    width: 30vw;
    height: 24vh;
    min-height: 0;
    overflow-y: auto;
    border: none !important;
    box-shadow: none !important;
    margin-top: 5px;
    margin-bottom: 10px;
}
</style>