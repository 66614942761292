import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/logo.png'
import _imports_1 from '../assets/binary_code.jpg'


const _hoisted_1 = { class: "top-framer" }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","gap":"10px"} }
const _hoisted_3 = { style: {"display":"flex","margin":"auto","width":"65%","justify-content":"flex-start"} }
const _hoisted_4 = { class: "foot-framer" }
const _hoisted_5 = { style: {"display":"flex","width":"50%","flex-direction":"column","align-items":"flex-start"} }
const _hoisted_6 = { style: {"display":"flex","justify-content":"flex-start","align-items":"center","align-self":"stretch"} }

import { Delete, Edit, Search, Share, Upload } from '@element-plus/icons-vue'
import { ref } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'Layout',
  setup(__props) {

const input = ref('')
const showTextbox = ref(false);


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "el-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { class: "el-header" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("img", {
                    alt: "logo",
                    src: _imports_0,
                    style: {"width":"160px","height":"35px"}
                  }, null, -1)
                ])),
                _: 1
              })
            ]),
            _createVNode(_component_el_col, {
              span: 6,
              style: {"display":"flex","align-items":"flex-end","justify-content":"flex-end"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, { to: "/search" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      icon: _unref(Search),
                      class: "component-font",
                      onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (showTextbox.value = true)),
                      style: {"background-color":"rgb(247,245,243)","color":"rgb(17,51,106)","border-color":"rgb(17,51,106)"}
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Search")
                      ])),
                      _: 1
                    }, 8, ["icon"])
                  ]),
                  _: 1
                }),
                _cache[3] || (_cache[3] = _createTextVNode("   "))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_menu, {
              mode: "horizontal",
              class: "top-menu"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_menu_item, {
                  index: "Home",
                  class: "top-menu-item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/" }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Home")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, {
                  index: "Projects",
                  class: "top-menu-item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/projects" }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Projects")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, {
                  index: "Members",
                  class: "top-menu-item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/member" }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("Members")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, {
                  index: "Data released",
                  class: "top-menu-item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/data" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Data released")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, {
                  index: "Courses",
                  class: "top-menu-item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/Courses" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Course")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, {
                  index: "About",
                  class: "top-menu-item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/about" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("About")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, {
                  index: "Updates",
                  class: "top-menu-item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/update" }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Updates ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_main, { style: {"width":"65%","margin":"auto"} }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_el_divider, { "content-position": "center" }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createTextVNode("声明")
        ])),
        _: 1
      }),
      _createVNode(_component_el_footer, { class: "el-footer" }, {
        default: _withCtx(() => [
          _cache[21] || (_cache[21] = _createElementVNode("div", { style: {"width":"65%","margin":"auto"} }, [
            _createElementVNode("p", { class: "foot-text" }, " 北京城市实验室(BCL)致力于采用跨学科的方法来量化城市系统，为城市规划和治理提出新的技术方法和见解，并形成城市可持续发展所需的城市科学。实验室目前结合了城市规划、建筑设计、城市地理学、GIS、经济和计算机科学背景，有深厚的研究实力。有关BCL的任何问题，请发送电子邮件至:BeijingCityLab@gmail.com ")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: "/",
                  class: "bottom-text"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("Home")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/projects",
                  class: "bottom-text"
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("Projects")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/member",
                  class: "bottom-text"
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("Members")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/data",
                  class: "bottom-text"
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Data released")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/Courses",
                  class: "bottom-text"
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode("Course")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/about",
                  class: "bottom-text"
                }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("About")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/update",
                  class: "bottom-text"
                }, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("Updates ")
                  ])),
                  _: 1
                })
              ]),
              _cache[19] || (_cache[19] = _createElementVNode("div", null, [
                _createElementVNode("p", {
                  style: {"margin-left":"5px"},
                  class: "bottom-text"
                }, "Copyright@Beijing City Lab · All right reserved ")
              ], -1))
            ]),
            _cache[20] || (_cache[20] = _createElementVNode("div", null, [
              _createElementVNode("img", {
                alt: "logo",
                src: _imports_1,
                style: {"width":"96px","height":"96px"}
              })
            ], -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})