<template>
    <div v-for="member_set in response_data" :key="member_set.first_level" class = "member-group">
        <p style="color: #000;font-family: Arial;font-size: 36px;font-style: italic;font-weight: 700;line-height: 26px;letter-spacing: 0.9px;">
            {{ member_set.first_level }}
        </p>
        <div v-for="member_group in member_set.member_groups" class = "member-list">
            <p v-if="member_group.second_level" style="color: #000;font-family: Arial;font-size: 20px;font-style: italic;font-weight: 700;line-height: 26px;letter-spacing: 0.5px;">
            {{ member_group.second_level }}
            </p>
            <MemberList :items="member_group.member_list"></MemberList>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, nextTick, defineProps } from 'vue';
import axios from 'axios';
import MemberList from '@/components/MemberList.vue';
interface Member {
  name: string;
  memberDescribe: string;
  mail: string;
  personalPage: string;
  major: string;
  imageLink: string;
}

interface MemberGroup {
  second_level: string;
  member_list: Member[];
}

interface MemberSet {
  first_level: string;
  member_groups: MemberGroup[];
}
//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";
const response_data = ref<MemberSet[]>([]);
onMounted(async () => {
    document.title="北京城市实验室-成员"
    const response = await axios.get(BaseUrl + '/api/f/all_member');
    response_data.value = response.data;
    console.log(response.data);
})
</script>

<style scoped>
.member-group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    margin-bottom: 50px;
}

.member-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}
</style>