import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "header-image-container" }
const _hoisted_2 = { style: {"display":"flex","height":"13.25vh","align-items":"flex-start","gap":"var(--item-spacing-10, 10px)","align-self":"stretch"} }
const _hoisted_3 = { class: "our-lab" }
const _hoisted_4 = { style: {"display":"flex","padding":"50px 0px","flex-direction":"column","align-items":"center","gap":"10px"} }
const _hoisted_5 = {
  key: 0,
  style: {"display":"flex","width":"100%","justify-content":"space-between","align-items":"center","align-self":"stretch","margin":"0"}
}
const _hoisted_6 = { style: {"display":"flex","width":"45%","flex-direction":"column","align-items":"flex-start"} }
const _hoisted_7 = { style: {"margin-top":"5px","display":"flex","flex-direction":"column","align-items":"flex-start","gap":"6vh","align-self":"stretch","background":"rgba(41, 41, 41, 0.80)","height":"25vh"} }
const _hoisted_8 = { style: {"margin-left":"10px","height":"31px","align-self":"stretch","color":"#FFF","font-family":"Arial","font-size":"36px","font-style":"normal","font-weight":"700","line-height":"42px","letter-spacing":"0.9px"} }
const _hoisted_9 = { style: {"margin-left":"10px","height":"31px","align-self":"stretch","color":"#FFF","text-align":"justify","font-family":"Arial","font-size":"20px","font-style":"normal","font-weight":"400","line-height":"24px","letter-spacing":"0.5px"} }
const _hoisted_10 = { class: "vertical-two" }
const _hoisted_11 = { class: "vertical-one" }
const _hoisted_12 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"1vh","width":"11vw"} }
const _hoisted_13 = { class: "vertical-one-date-text" }
const _hoisted_14 = { class: "vertical-one" }
const _hoisted_15 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"1vh","width":"11vw"} }
const _hoisted_16 = { class: "vertical-one-date-text" }
const _hoisted_17 = { class: "vertical-two" }
const _hoisted_18 = { class: "vertical-one" }
const _hoisted_19 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"1vh","width":"11vw"} }
const _hoisted_20 = { class: "vertical-one-date-text" }
const _hoisted_21 = { class: "vertical-one" }
const _hoisted_22 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"1vh","width":"11vw"} }
const _hoisted_23 = { class: "vertical-one-date-text" }
const _hoisted_24 = { class: "vertical-two" }
const _hoisted_25 = { class: "vertical-one" }
const _hoisted_26 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"1vh","width":"11vw"} }
const _hoisted_27 = { class: "vertical-one-date-text" }
const _hoisted_28 = { class: "vertical-one" }
const _hoisted_29 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"1vh","width":"11vw"} }
const _hoisted_30 = { class: "vertical-one-date-text" }

import axios from 'axios';
import { onMounted, ref, nextTick } from 'vue';

////const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const head_images_links = ref<Array<String>>([]);
const middle_images_links = ref<Array<String>>([]);
const bottom_projects_infos = ref<Array<Object>>([]);
var bottom_projects_ids = [];

onMounted(async () => {
  document.title="北京城市实验室"
  const data = (await axios.get(BaseUrl + '/api/f/homepage')).data;
  for (let i = 0; i < data.length; i++) {
    if (data[i].location == 1) {
      head_images_links.value.push(data[i].imageLink);
    }
    else if (data[i].location == 2) {
      middle_images_links.value.push(data[i].imageLink);
    }
    else {
      bottom_projects_ids.push(data[i].projectId);
      bottom_projects_infos.value.push({
        'project_ID': data[i].projectId,
        'image_link': data[i].imageLink,
        'project_name': '',
        'project_insert_time': ''
      });
    }
  }

  const project_infos = (await axios.get(BaseUrl + '/api/f/projects/project_detail/project_infos?projectIDs=' + bottom_projects_ids.toString())).data;

  for (let i = 0; i < project_infos.length; i++) {
    bottom_projects_infos.value[i]['project_name'] = project_infos[i].projectName;
    bottom_projects_infos.value[i]['image_link'] = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/project/"+project_infos[i].projectName+'/cover'
    bottom_projects_infos.value[i]['project_insert_time'] = new Date(project_infos[i].projectInsertTime).toLocaleDateString();
  }
  console.log(head_images_links.value);
  console.log(middle_images_links.value);
  console.log(bottom_projects_infos.value[6].image_link);
})

return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_text = _resolveComponent("el-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_image, {
        style: {"width":"100%","height":"auto"},
        src: head_images_links.value[0],
        "preview-src-list": [head_images_links.value[0]],
        fit: "cover"
      }, null, 8, ["src", "preview-src-list"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_image, {
          style: {"flex":"1 0 0","align-self":"stretch"},
          src: head_images_links.value[1],
          "preview-src-list": [head_images_links.value[1]],
          fit: "cover"
        }, null, 8, ["src", "preview-src-list"]),
        _createVNode(_component_el_image, {
          style: {"flex":"1 0 0","align-self":"stretch"},
          src: head_images_links.value[2],
          "preview-src-list": [head_images_links.value[2]],
          fit: "cover"
        }, null, 8, ["src", "preview-src-list"]),
        _createVNode(_component_el_image, {
          style: {"flex":"1 0 0","align-self":"stretch"},
          src: head_images_links.value[3],
          "preview-src-list": [head_images_links.value[3]],
          fit: "cover"
        }, null, 8, ["src", "preview-src-list"]),
        _createVNode(_component_el_image, {
          style: {"flex":"1 0 0","align-self":"stretch"},
          src: head_images_links.value[4],
          "preview-src-list": [head_images_links.value[4]],
          fit: "cover"
        }, null, 8, ["src", "preview-src-list"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"display":"flex","width":"49%","flex-direction":"column","justify-content":"space-between","align-items":"flex-start","flex-shrink":"0","align-self":"stretch"} }, [
        _createElementVNode("p", { style: {"margin":"0","display":"flex","justify-content":"center","align-items":"center","color":"#000","text-align":"justify","font-family":"Arial","font-size":"36px","font-style":"normal","font-weight":"700","line-height":"24px","letter-spacing":"0.9px"} }, " Our Lab"),
        _createElementVNode("p", { style: {"display":"flex","justify-content":"center","align-items":"center","color":"#000","text-align":"justify","font-family":"Arial","font-size":"20px","font-style":"normal","font-weight":"700","line-height":"30px","letter-spacing":"0.5px"} }, " Beijing City Lab"),
        _createElementVNode("p", { style: {"display":"flex","margin":"0","justify-content":"space-between","align-items":"center","align-self":"stretch","flex":"1 0 0","color":"#000","text-align":"justify","font-family":"Arial","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"24px","letter-spacing":"0.4px"} }, " The Beijing City Lab (BCL) is a dedicating to studying, but not limited to, China’s capital Beijing. The Lab focuses on employing interdisciplinary methods to quantify urban dynamics, generating new insights for urban planning and governance, and ultimately producing the science of cities required for sustainable urban development. The lab's current mix of urban planning, architecture, urban geography, GIS, economy, and computer science background lends unique research strength. ")
      ], -1)),
      _createVNode(_component_el_image, {
        style: {"width":"49%","height":"30vh","align-self":"stretch"},
        src: middle_images_links.value[0],
        "preview-src-list": middle_images_links.value,
        fit: "cover"
      }, null, 8, ["src", "preview-src-list"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("p", { style: {"display":"flex","padding-bottom":"10px","align-items":"flex-start","gap":"10px","align-self":"stretch","color":"#000","text-align":"justify","font-family":"Arial","font-size":"36px","font-style":"normal","font-weight":"700","line-height":"24px","letter-spacing":"0.9px"} }, " Latest Projects", -1)),
      (bottom_projects_infos.value[6])
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: `/projects/${bottom_projects_infos.value[6].project_ID+1}`
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, {
                    style: {"margin":"0","display":"flex","align-items":"center","gap":"10px","align-self":"stretch","height":"25vh"},
                    src: bottom_projects_infos.value[6].image_link||defaultImage,
                    fit: "cover"
                  }, null, 8, ["src"])
                ]),
                _: 1
              }, 8, ["to"]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, [
                  _createVNode(_component_router_link, {
                    to: `/projects/${bottom_projects_infos.value[6].project_ID+1}`,
                    style: {"text-decoration":"none","color":"inherit"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(bottom_projects_infos.value[6].project_name), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("p", _hoisted_9, _toDisplayString(bottom_projects_infos.value[6].project_insert_time), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_router_link, {
                  to: `/projects/${bottom_projects_infos.value[5].project_ID+1}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_image, {
                      class: "vertical-one-image",
                      src: bottom_projects_infos.value[5].image_link||defaultImage,
                      fit: "cover"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_el_text, {
                    class: "vertical-one-title-text",
                    truncated: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: `/projects/${bottom_projects_infos.value[5].project_ID+1}`,
                        style: {"text-decoration":"none","color":"inherit"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(bottom_projects_infos.value[5].project_name), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_13, _toDisplayString(bottom_projects_infos.value[5].project_insert_time), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_router_link, {
                  to: `/projects/${bottom_projects_infos.value[4].project_ID+1}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_image, {
                      class: "vertical-one-image",
                      src: bottom_projects_infos.value[4].image_link||defaultImage,
                      fit: "cover"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_el_text, {
                    class: "vertical-one-title-text",
                    truncated: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: `/projects/${bottom_projects_infos.value[4].project_ID+1}`,
                        style: {"text-decoration":"none","color":"inherit"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(bottom_projects_infos.value[4].project_name), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_16, _toDisplayString(bottom_projects_infos.value[4].project_insert_time), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_router_link, {
                  to: `/projects/${bottom_projects_infos.value[3].project_ID+1}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_image, {
                      class: "vertical-one-image",
                      src: bottom_projects_infos.value[3].image_link||defaultImage,
                      fit: "cover"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_el_text, {
                    class: "vertical-one-title-text",
                    truncated: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: `/projects/${bottom_projects_infos.value[3].project_ID+1}`,
                        style: {"text-decoration":"none","color":"inherit"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(bottom_projects_infos.value[3].project_name), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_20, _toDisplayString(bottom_projects_infos.value[3].project_insert_time), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_router_link, {
                  to: `/projects/${bottom_projects_infos.value[2].project_ID+1}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_image, {
                      class: "vertical-one-image",
                      src: bottom_projects_infos.value[2].image_link||defaultImage,
                      fit: "cover"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_el_text, {
                    class: "vertical-one-title-text",
                    truncated: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: `/projects/${bottom_projects_infos.value[2].project_ID+1}`,
                        style: {"text-decoration":"none","color":"inherit"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(bottom_projects_infos.value[2].project_name), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_23, _toDisplayString(bottom_projects_infos.value[2].project_insert_time), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_router_link, {
                  to: `/projects/${bottom_projects_infos.value[1].project_ID+1}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_image, {
                      class: "vertical-one-image",
                      src: bottom_projects_infos.value[1].image_link||defaultImage,
                      fit: "cover"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_el_text, {
                    class: "vertical-one-title-text",
                    truncated: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: `/projects/${bottom_projects_infos.value[1].project_ID+1}`,
                        style: {"text-decoration":"none","color":"inherit"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(bottom_projects_infos.value[1].project_name), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_27, _toDisplayString(bottom_projects_infos.value[1].project_insert_time), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_router_link, {
                  to: `/projects/${bottom_projects_infos.value[0].project_ID+1}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_image, {
                      class: "vertical-one-image",
                      src: bottom_projects_infos.value[0].image_link||defaultImage,
                      fit: "cover"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("div", _hoisted_29, [
                  _createVNode(_component_el_text, {
                    class: "vertical-one-title-text",
                    truncated: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: `/projects/${bottom_projects_infos.value[0].project_ID+1}`,
                        style: {"text-decoration":"none","color":"inherit"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(bottom_projects_infos.value[0].project_name), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_30, _toDisplayString(bottom_projects_infos.value[0].project_insert_time), 1)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})