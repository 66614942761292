<template>
  <div class="header-image-container">
    <el-image style="width: 100%;height: auto;" :src="head_images_links[0]" :preview-src-list="[head_images_links[0]]"
      fit="cover" />
    <div
      style="display: flex;height: 13.25vh;align-items: flex-start;gap: var(--item-spacing-10, 10px);align-self: stretch;">
      <el-image style="flex: 1 0 0;align-self: stretch;" :src="head_images_links[1]"
        :preview-src-list="[head_images_links[1]]" fit="cover" />
      <el-image style="flex: 1 0 0;align-self: stretch;" :src="head_images_links[2]"
        :preview-src-list="[head_images_links[2]]" fit="cover" />
      <el-image style="flex: 1 0 0;align-self: stretch;" :src="head_images_links[3]"
        :preview-src-list="[head_images_links[3]]" fit="cover" />
      <el-image style="flex: 1 0 0;align-self: stretch;" :src="head_images_links[4]"
        :preview-src-list="[head_images_links[4]]" fit="cover" />
    </div>
  </div>

  <div class="our-lab">
    <div
      style=" display: flex;width: 49%; flex-direction: column;justify-content: space-between;align-items: flex-start;flex-shrink: 0;align-self: stretch;">
      <p
        style="margin: 0; display: flex;justify-content: center;align-items: center;
      color: #000;text-align: justify;font-family: Arial;font-size: 36px;font-style: normal;font-weight: 700;line-height: 24px; /* 66.667% */letter-spacing: 0.9px;">
        Our Lab</p>
      <p
        style="display: flex;justify-content: center;align-items: center;
      color: #000;text-align: justify;font-family: Arial;font-size: 20px;font-style: normal;font-weight: 700;line-height: 30px; /* 150% */letter-spacing: 0.5px;">
        Beijing City Lab</p>
      <p
        style="display: flex;margin: 0;justify-content: space-between;align-items: center;align-self: stretch;flex: 1 0 0;
      color: #000;text-align: justify;font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px; /* 150% */letter-spacing: 0.4px;">
        The Beijing City Lab (BCL) is a dedicating to studying, but not limited to, China’s capital Beijing. The Lab
        focuses on employing interdisciplinary methods to quantify urban dynamics, generating new insights for urban
        planning and governance, and ultimately producing the science of cities required for sustainable urban
        development. The lab's current mix of urban planning, architecture, urban geography, GIS, economy, and computer
        science background lends unique research strength.
      </p>
    </div>
    <el-image style="width: 49%; height: 30vh;align-self: stretch;" :src="middle_images_links[0]"
      :preview-src-list="middle_images_links" fit="cover"></el-image>
  </div>
  <div style="display: flex;padding: 50px 0px;flex-direction: column;align-items: center;gap: 10px;">
    <p
      style="display: flex;padding-bottom: 10px;align-items: flex-start;gap: 10px;align-self: stretch;
    color: #000;text-align: justify;font-family: Arial;font-size: 36px;font-style: normal;font-weight: 700;line-height: 24px; /* 66.667% */letter-spacing: 0.9px;">
      Latest Projects</p>
    <!-- 底部项目集合的排版 -->
    <div v-if="bottom_projects_infos[6]"
      style="display: flex;width: 100%;justify-content: space-between;align-items: center;align-self: stretch;margin:0;">
      <!-- 最近的一个项目，最大的那个 -->
      <div style="display: flex;width: 45%;flex-direction: column;align-items: flex-start;">
        <router-link :to="`/projects/${bottom_projects_infos[6].project_ID+1}`">
          <el-image style="margin:0;display: flex;align-items: center;gap: 10px;align-self: stretch;height: 25vh;"
            :src="bottom_projects_infos[6].image_link||defaultImage" fit="cover"></el-image></router-link>
        <div
          style="margin-top: 5px;display: flex;flex-direction: column;align-items: flex-start;gap: 6vh;align-self: stretch;background: rgba(41, 41, 41, 0.80);height: 25vh">
          <p
            style="margin-left: 10px;height: 31px;align-self: stretch;color: #FFF;font-family: Arial;font-size: 36px;font-style: normal;font-weight: 700;line-height: 42px; /* 66.667% */letter-spacing: 0.9px;">
            <router-link :to="`/projects/${bottom_projects_infos[6].project_ID+1}`" style="text-decoration: none; color: inherit;">
              {{ bottom_projects_infos[6].project_name }}</router-link>
          </p>
          <p
            style="margin-left: 10px; height: 31px;align-self: stretch;color: #FFF;text-align: justify;font-family: Arial;font-size: 20px;font-style: normal;font-weight: 400;line-height: 24px; /* 120% */letter-spacing: 0.5px;">
            {{ bottom_projects_infos[6].project_insert_time }}
          </p>
        </div>
      </div>
      <!-- 一列两行的项目 -->
      <div class="vertical-two">
        <div class="vertical-one">
          <router-link :to="`/projects/${bottom_projects_infos[5].project_ID+1}`">
            <el-image class="vertical-one-image" :src="bottom_projects_infos[5].image_link||defaultImage" fit="cover"></el-image>
          </router-link>
          <div style="display: flex;flex-direction: column;align-items: flex-start;gap: 1vh;width: 11vw;">
            <el-text class="vertical-one-title-text" truncated>
              <router-link :to="`/projects/${bottom_projects_infos[5].project_ID+1}`" style="text-decoration: none; color: inherit;">
                {{ bottom_projects_infos[5].project_name }}</router-link></el-text>
            <p class="vertical-one-date-text">
              {{ bottom_projects_infos[5].project_insert_time }}</p>
          </div>
        </div>
        <div class="vertical-one">
          <router-link :to="`/projects/${bottom_projects_infos[4].project_ID+1}`">
            <el-image class="vertical-one-image" :src="bottom_projects_infos[4].image_link||defaultImage" fit="cover"></el-image>
          </router-link>
          <div style="display: flex;flex-direction: column;align-items: flex-start;gap: 1vh;width: 11vw;">
            <el-text class="vertical-one-title-text" truncated>
              <router-link :to="`/projects/${bottom_projects_infos[4].project_ID+1}`" style="text-decoration: none; color: inherit;">
                {{ bottom_projects_infos[4].project_name }}</router-link></el-text>
            <p class="vertical-one-date-text">
              {{ bottom_projects_infos[4].project_insert_time }}</p>
          </div>
        </div>
      </div>
      <!-- 一列两行的项目 -->
      <div class="vertical-two">
        <div class="vertical-one">
          <router-link :to="`/projects/${bottom_projects_infos[3].project_ID+1}`">
            <el-image class="vertical-one-image" :src="bottom_projects_infos[3].image_link||defaultImage" fit="cover"></el-image>
          </router-link>
          <div style="display: flex;flex-direction: column;align-items: flex-start;gap: 1vh;width: 11vw;">
            <el-text class="vertical-one-title-text" truncated>
              <router-link :to="`/projects/${bottom_projects_infos[3].project_ID+1}`" style="text-decoration: none; color: inherit;">
                {{ bottom_projects_infos[3].project_name }}</router-link></el-text>
            <p class="vertical-one-date-text">
              {{ bottom_projects_infos[3].project_insert_time }}</p>
          </div>
        </div>
        <div class="vertical-one">
          <router-link :to="`/projects/${bottom_projects_infos[2].project_ID+1}`">
            <el-image class="vertical-one-image" :src="bottom_projects_infos[2].image_link||defaultImage" fit="cover"></el-image>
          </router-link>
          <div style="display: flex;flex-direction: column;align-items: flex-start;gap: 1vh;width: 11vw;">
            <el-text class="vertical-one-title-text" truncated>
              <router-link :to="`/projects/${bottom_projects_infos[2].project_ID+1}`" style="text-decoration: none; color: inherit;">
                {{ bottom_projects_infos[2].project_name }}</router-link></el-text>
            <p class="vertical-one-date-text">
              {{ bottom_projects_infos[2].project_insert_time }}</p>
          </div>
        </div>
      </div>
      <!-- 一列两行的项目 -->
      <div class="vertical-two">
        <div class="vertical-one">
          <router-link :to="`/projects/${bottom_projects_infos[1].project_ID+1}`">
            <el-image class="vertical-one-image" :src="bottom_projects_infos[1].image_link||defaultImage" fit="cover"></el-image>
          </router-link>
          <div style="display: flex;flex-direction: column;align-items: flex-start;gap: 1vh;width: 11vw;">
            <el-text class="vertical-one-title-text" truncated>
              <router-link :to="`/projects/${bottom_projects_infos[1].project_ID+1}`" style="text-decoration: none; color: inherit;">
                {{ bottom_projects_infos[1].project_name }}</router-link></el-text>
            <p class="vertical-one-date-text">
              {{ bottom_projects_infos[1].project_insert_time }}</p>
          </div>
        </div>
        <div class="vertical-one">
          <router-link :to="`/projects/${bottom_projects_infos[0].project_ID+1}`">
            <el-image class="vertical-one-image" :src="bottom_projects_infos[0].image_link||defaultImage" fit="cover"></el-image>
          </router-link>
          <div style="display: flex;flex-direction: column;align-items: flex-start;gap: 1vh;width: 11vw;">
            <el-text class="vertical-one-title-text" truncated>
              <router-link :to="`/projects/${bottom_projects_infos[0].project_ID+1}`" style="text-decoration: none; color: inherit;">
                {{ bottom_projects_infos[0].project_name }}</router-link></el-text>
            <p class="vertical-one-date-text">
              {{ bottom_projects_infos[0].project_insert_time }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<style scoped>
.header-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--item-spacing-10, 10px);
  align-self: stretch;
}

.our-lab {
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
  align-items: center;
}

.vertical-two {
  display: flex;
  width: 22%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.vertical-one {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  align-self: stretch;
  border-bottom: 1px solid #E1E1E1;
  gap: 1.95vh;
}

.vertical-one-image {
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  height: 13vh;
}

.vertical-one-title-text {
  white-space: nowrap;
  /* 禁止换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 显示省略号 */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #000;
  text-align: justify;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 120% */
  letter-spacing: 0.5px;
}

.vertical-one-date-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: rgba(94, 94, 94, 0.93);
  text-align: justify;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.4px;
}
</style>


<script lang="ts" setup>
import axios from 'axios';
import { onMounted, ref, nextTick, defineProps } from 'vue';

////const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";
const head_images_links = ref<Array<String>>([]);
const middle_images_links = ref<Array<String>>([]);
const bottom_projects_infos = ref<Array<Object>>([]);
var bottom_projects_ids = [];

onMounted(async () => {
  document.title="北京城市实验室"
  const data = (await axios.get(BaseUrl + '/api/f/homepage')).data;
  for (let i = 0; i < data.length; i++) {
    if (data[i].location == 1) {
      head_images_links.value.push(data[i].imageLink);
    }
    else if (data[i].location == 2) {
      middle_images_links.value.push(data[i].imageLink);
    }
    else {
      bottom_projects_ids.push(data[i].projectId);
      bottom_projects_infos.value.push({
        'project_ID': data[i].projectId,
        'image_link': data[i].imageLink,
        'project_name': '',
        'project_insert_time': ''
      });
    }
  }

  const project_infos = (await axios.get(BaseUrl + '/api/f/projects/project_detail/project_infos?projectIDs=' + bottom_projects_ids.toString())).data;

  for (let i = 0; i < project_infos.length; i++) {
    bottom_projects_infos.value[i]['project_name'] = project_infos[i].projectName;
    bottom_projects_infos.value[i]['image_link'] = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/project/"+project_infos[i].projectName+'/cover'
    bottom_projects_infos.value[i]['project_insert_time'] = new Date(project_infos[i].projectInsertTime).toLocaleDateString();
  }
  console.log(head_images_links.value);
  console.log(middle_images_links.value);
  console.log(bottom_projects_infos.value[6].image_link);
})
</script>