<template>
    <p style="color: #000;text-align: justify;font-family: Arial;font-size: 36px;font-style: normal;font-weight: 700;line-height: 24px;letter-spacing: 0.9px;
    align-items: center;text-align: center;">
        Dr Ying Long 龙瀛
    </p>
<div v-for="(data,index) in data_detail_list" :key="index">
    <p class = "title">
        {{ data.title }}
    </p>
    <div :id="'viewer' + index" class="richtext"></div>
    <el-button  v-if="data.title=='About me and my lab'" class="download-bottom" @click="() => {file_table = true }">
        Download</el-button>
    <el-divider style="margin: 3px;"></el-divider>
</div>
<el-drawer v-model="file_table"  direction="rtl" size="30%" :before-close="handleClose"
        style="color: #000;font-family: Arial;font-size: 20px;font-style: italic;font-weight: 700;line-height: normal;">
        <template #title>
          <div>
            Files from
            <br />
            {{ selected_journal_name }}
          </div>
        </template>
        <div v-if="file_list"
            style="display: flex;flex-direction: column;align-items: flex-start;flex: 1 0 0;align-self: stretch;">
            <div v-for="(file, file_index) in file_list" :key="file_index"
                style="margin:0;display: flex;flex-direction: column;align-items: flex-start;align-self: stretch;">
                <div
                    style="display: flex;justify-content: space-between;align-items: center;align-self: stretch;display: flex;padding: 0px;align-items: center;margin-bottom: 0;">
                    <el-tooltip class="box-item" effect="dark" :content="/\.[a-zA-Z0-9]+$/.test(file.fileName)
                        ? file.fileName
                        : decodeURIComponent(file.fileLink.substring(file.fileLink.lastIndexOf('/') + 1))"
                        placement="left">
                        <el-text style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;
                    margin-top: 5px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:18vw ;"
                            truncated>
                            {{
                                /\.[a-zA-Z0-9]+$/.test(file.fileName)
                                    ? file.fileName
                                    :
                                    decodeURIComponent(file.fileLink.substring(file.fileLink.lastIndexOf('/')
                                        + 1))
                            }}
                        </el-text>
                    </el-tooltip>
                    <p
                        style="margin:0;color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;">
                        {{ file.fileSize }}
                    </p>
                </div>
                <div
                    style="display: flex;padding: 0px;justify-content: flex-end;align-items: center;gap: 10px;align-self: stretch;">
                    <p style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;">
                        <a :href="file.fileLink" style="color: inherit;">Download</a>
                    </p>
                </div>
                <el-divider style="margin: 3px;"></el-divider>
            </div>
        </div>
    </el-drawer>
</template>


<script lang="ts" setup>
import { onMounted, ref, defineProps,nextTick } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router'
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";

const quill_list = ref<Array<Quill>>([]);
const data_detail_list = ref([]);

const file_table = ref(false)
const file_list = ref([]);
onMounted(async () => {
    document.title="北京城市实验室-龙瀛"
    const response = await axios.get(BaseUrl +"/api/f/longying");
    data_detail_list.value = response.data;
    nextTick(() => {
        for (let index = 0; index < data_detail_list.value.length; index++) {
            const viewerId = `#viewer${index}`;
            const readonlyquill = new Quill(viewerId, {
                theme: 'snow',
                readOnly: true,
                modules: {
                    toolbar: false,
                }
            });
            readonlyquill.root.innerHTML = data_detail_list.value[index].content;
            quill_list.value.push(readonlyquill);
        }
    });

    file_list.value = (await axios.get(BaseUrl +"/api/f/longying/file")).data;
    console.log(file_list.value)
})

const handleClose = (done: () => void) => {
    file_table.value = false; // 显式地关闭抽屉
    done();
}
</script>


<style scoped>
.title{
    color: #000;
    font-family: Arial;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    line-height: 26px; /* 130% */
    letter-spacing: 0.5px;
}

.richtext {
    width: 100%;
    height: auto;
    min-height: 0vh !important;
    overflow-y: hidden;
    border: none !important;
    box-shadow: none !important;
    margin-top: 5px;
    margin-bottom: 10px;
}

.download-bottom {
    height: 0.6vh;
    padding: 16px 32px;
    margin-left:52vw;
    gap: 8px;
    background: var(--Base-Black-100, #222325);
    color: var(--Base-White-100, #FFF);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
}
</style>