<template>
    <el-container class="el-container">
        <el-header class="el-header">
            <div class="top-framer">

                <div style="display: flex;align-items: center;gap: 10px;">
                    <router-link to="/"><img alt="logo" src="../assets/logo.png"
                            style="width: 160px; height: 35px;"></router-link>
                </div>

                <el-col :span="6" style="display: flex; align-items: flex-end; justify-content: flex-end;">
                    <!-- <el-input v-if="showTextbox" v-model="input" style="width: 170px;height: 34px;"
                        placeholder="Please input" /> -->
                        <router-link to="/search">
                    <el-button type="primary" :icon="Search" class="component-font"  @mouseenter="showTextbox = true"
                        style="background-color: rgb(247,245,243);color:rgb(17,51,106);border-color:rgb(17,51,106); ">Search</el-button></router-link>
                    &#8197;
                </el-col>
            </div>
            <div style="display: flex; margin: auto;width: 65%;justify-content: flex-start; ">

                <!-- <p class="LOGO-TEXT">&#8197;THU Beijing City Lab </p> -->
                <el-menu mode="horizontal" class="top-menu">
                    <el-menu-item index="Home" class="top-menu-item">
                        <router-link to="/">Home</router-link>
                    </el-menu-item>
                    <el-menu-item index="Projects" class="top-menu-item">
                        <router-link to="/projects">Projects</router-link>
                    </el-menu-item>
                    <el-menu-item index="Members" class="top-menu-item">
                         <router-link to="/member">Members</router-link>
                        </el-menu-item>
                    <el-menu-item index="Data released" class="top-menu-item">
                        <router-link to="/data"> Data released</router-link>
                    </el-menu-item>
                    <el-menu-item index="Courses" class="top-menu-item"> 
                        <router-link to="/Courses">Course</router-link>
                    </el-menu-item>
                    <el-menu-item index="About" class="top-menu-item"> 
                        <router-link to="/about">About</router-link>
                    </el-menu-item>
                    <el-menu-item index="Updates" class="top-menu-item"> 
                        <router-link to="/update">Updates&#8197;</router-link>
                        
                    </el-menu-item>

                </el-menu>
            </div>
        </el-header>
        <el-main style="width: 65%;margin: auto;">

            <router-view></router-view>
        </el-main>
        <el-divider content-position="center">声明</el-divider>
        <el-footer class="el-footer">
            <div style="width: 65%;margin: auto;">
                <p class="foot-text">
                    北京城市实验室(BCL)致力于采用跨学科的方法来量化城市系统，为城市规划和治理提出新的技术方法和见解，并形成城市可持续发展所需的城市科学。实验室目前结合了城市规划、建筑设计、城市地理学、GIS、经济和计算机科学背景，有深厚的研究实力。有关BCL的任何问题，请发送电子邮件至:BeijingCityLab@gmail.com
                </p>
            </div>
            <div class="foot-framer">
                <div style="display: flex;width: 50%;flex-direction: column;align-items: flex-start;">
                    <div style="display: flex;justify-content: flex-start;align-items: center;align-self: stretch;">
                        <router-link to="/" class="bottom-text">Home</router-link>
                        <router-link to="/projects" class="bottom-text">Projects</router-link>
                        <router-link to="/member"class="bottom-text">Members</router-link>
                        <router-link to="/data"class="bottom-text"> Data released</router-link>
                        <router-link to="/Courses"class="bottom-text">Course</router-link>
                        <router-link to="/about"class="bottom-text">About</router-link>
                        <router-link to="/update"class="bottom-text">Updates&#8197;</router-link>
                    </div>
                    <div>
                        <p style="margin-left: 5px;" class="bottom-text">Copyright@Beijing City Lab · All right reserved
                        </p>
                    </div>
                </div>
                <div>
                    <img alt="logo" src="../assets/binary_code.jpg" style="width: 96px; height: 96px;">
                </div>
            </div>
        </el-footer>
    </el-container>
</template>

<style scoped>
.el-container {
    display: flex;
    margin: auto;
    justify-content: center;
    /* 居中 */
    align-items: center;
    /* 垂直居中 */

}

.el-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-bottom: 5px solid #000;
    background: #FFF;
}

.top-framer {
    display: flex;
    margin: auto;
    width: 65%;
    justify-content: space-between;
    align-items: center;
}

.foot-framer {
    display: flex;
    margin: auto;
    width: 65%;
    justify-content: space-between;
    align-items: flex-start;
}

.LOGO-TEXT {
    color: #000;
    font-family: "Archivo Narrow";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.5px;
}

.top-menu {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    --el-menu-active-color: #002480;
    margin-top: 0;
    gap: 38px;
}

.top-menu-item {
    margin: 0 !important;
    /* 移除外边距 */
    padding: 0 0px;
    /* 根据需要调整内边距 */
    color: #002480;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
    letter-spacing: 0.4px;
}

.el-footer {
    display: flex;
    height: auto;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.foot-text {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: 0.4px;
}

.bottom-text {
    margin-right: 1vw;
    white-space: nowrap!important;
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    letter-spacing: 0.4px;
}
</style>

<script setup lang="ts">
import { Delete, Edit, Search, Share, Upload } from '@element-plus/icons-vue'
import { ref } from 'vue'
const input = ref('')
const showTextbox = ref(false);

</script>