import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "images-container" }
const _hoisted_2 = { class: "overlay-text" }

import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
 const BaseUrl = "https://api.beijingcitylab.org:8080"

export default /*@__PURE__*/_defineComponent({
  __name: 'CourseIndexView',
  setup(__props) {

const images = ref<Array<{ url: string; text: string,course_ID: string }>>([]);
const router = useRouter();
const fetchImages = async () => {
    try {
         const response = await axios.get(BaseUrl+ '/api/f/course/index');
        
        images.value = response.data.map((item: { link: any; index: string; name: string; }) => ({
            url: item.link,
            text: item.index + "-" + item.name,
            course_ID: item.index
        }));
    } catch (error) {
        console.error('Error fetching images:', error);
    }
};

onMounted(() => {
    document.title="北京城市实验室-课程列表"
    fetchImages();
});


return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (image, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "image-container",
        key: index
      }, [
        _createVNode(_component_router_link, {
          to: `/courses/${image.course_ID}`
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_image, {
              style: {"width":"19.3vw","height":"10.6vw"},
              src: image.url,
              fit: "scale-down"
            }, null, 8, ["src"])
          ]),
          _: 2
        }, 1032, ["to"]),
        _createVNode(_component_router_link, {
          to: `/courses/${image.course_ID}`
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "mask" }, null, -1)
          ])),
          _: 2
        }, 1032, ["to"]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(image.text), 1)
      ]))
    }), 128))
  ]))
}
}

})