<template>
  <p class="title">
    BCL-Introduction
  </p>
  <div v-html="introduction"></div>
  <p class="title" style="margin-bottom: 50px;">
    Supporters & Collaborators
  </p>
  <img style="width: 100%;height: auto;" :src="sc_logo" />
  <p class="title">
    Journals
  </p>
    <div class="images-container">
    <!-- 使用 v-for 循环渲染多个图像 -->
    <div class="image-container" v-for="(journal, index) in journal_data" :key="index">
      <a :href=journal.journal_link target="_blank" rel="noopener noreferrer">
        <el-image style="width: 19.3vw;height: 26.2vw;" :src="journal.journal_cover" fit="scale-down" />
      </a>
      <el-text truncated
        style="color: #000; font-family: Arial; font-size: 20px; font-style: italic; font-weight: 700; line-height: 26px; letter-spacing: 0.5px; margin: 0;width: 19.3vw;">
        {{ journal.journal_name }}
      </el-text>
      <el-button style="color: #000;font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;letter-spacing: 0.3px;padding:0 ;"
      @click="download_journal_files(journal.journal_uuid, journal.journal_name)"  text>
        more>
      </el-button>
    </div>
  </div>
  
 <p class="title">
    conferences
  </p>
  <div class="images-container">
    <!-- 使用 v-for 循环渲染多个图像 -->
    <div class="image-container" v-for="(conference, index) in conference_data" :key="index">
      <el-image style="width: 19.3vw;height: 13.2vw;" :src="conference.conferenceCover" fit="scale-down" 
      @click="goToConferencePage(conference.conferenceName,conference.uuid,conference.conferenceSecondTitle)" />
      <el-text truncated @click="goToConferencePage(conference.conferenceName,conference.uuid,conference.conferenceSecondTitle)"
        style="color: #000; font-family: Arial; font-size: 20px; font-style: italic; font-weight: 700; line-height: 26px; letter-spacing: 0.5px; margin: 0;width: 19.3vw;">
        {{ conference.conferenceName }}
      </el-text>
    </div>
  </div>

  <el-drawer v-model="file_table"  direction="rtl" size="30%" :before-close="handleClose"
        style="color: #000;font-family: Arial;font-size: 20px;font-style: italic;font-weight: 700;line-height: normal;">
        <template #title>
          <div>
            Files from
            <br />
            {{ selected_journal_name }}
          </div>
        </template>
        <div v-if="journal_files"
            style="display: flex;flex-direction: column;align-items: flex-start;flex: 1 0 0;align-self: stretch;">
            <div v-for="(file, file_index) in journal_files" :key="file_index"
                style="margin:0;display: flex;flex-direction: column;align-items: flex-start;align-self: stretch;">
                <div
                    style="display: flex;justify-content: space-between;align-items: center;align-self: stretch;display: flex;padding: 0px;align-items: center;margin-bottom: 0;">
                    <el-tooltip class="box-item" effect="dark" :content="/\.[a-zA-Z0-9]+$/.test(file.journalFileName)
                        ? file.journalFileName
                        : decodeURIComponent(file.journalFileLink.substring(file.journalFileLink.lastIndexOf('/') + 1))"
                        placement="left">
                        <el-text style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;
                    margin-top: 5px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:18vw ;"
                            truncated>
                            {{
                                /\.[a-zA-Z0-9]+$/.test(file.journalFileName)
                                    ? file.journalFileName
                                    :
                                    decodeURIComponent(file.journalFileLink.substring(file.journalFileLink.lastIndexOf('/')
                                        + 1))
                            }}
                        </el-text>
                    </el-tooltip>
                    <p
                        style="margin:0;color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;">
                        {{ file.journalFileSize }}
                    </p>
                </div>
                <div
                    style="display: flex;padding: 0px;justify-content: flex-end;align-items: center;gap: 10px;align-self: stretch;">
                    <p style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;">
                        <a :href="file.journalFileLink" style="color: inherit;">Download</a>
                    </p>
                </div>
                <el-divider style="margin: 3px;"></el-divider>
            </div>
        </div>
    </el-drawer>
</template>


<script lang="ts" setup>
import { onMounted, ref, defineProps } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router'

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
const BaseUrl = "https://api.beijingcitylab.org:8080";
const sc_logo = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/about/logo";
const router = useRouter();
const introduction = ref("");
const journal_data = ref<Array<{ journal_uuid: string,journal_name: string, journal_cover: string, journal_link: string }>>([]);
const conference_data = ref([]);
onMounted(async () => {
  document.title="北京城市实验室-关于"
  const intro_response = await axios.get(BaseUrl + "/api/f/update");
  introduction.value = intro_response.data.introduction;
  introduction.value = introduction.value
    .split('\n')
    .map(paragraph => `<p style="color: #000;text-align: justify;font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;
    line-height: 24px; /* 150% */letter-spacing: 0.4px;margin-bottom: 32px;">${paragraph}</p>`)
    .join('');

  const journal_response = await axios.get(BaseUrl + "/api/f/about/journal");
  journal_data.value = journal_response.data.map((item: {uuid: string, journalLink: any; journalCover: any; journalName: any; }) => ({
    journal_name: item.journalName,
    journal_cover: item.journalCover,
    journal_link: item.journalLink,
    journal_uuid:item.uuid
  }));
  const conference_response = await axios.get(BaseUrl+"/api/f/about/conference/all");
  conference_data.value = conference_response.data;
  console.log(conference_data.value);
})

const journal_files = ref([]);
const selected_journal_name = ref("");
const file_table = ref(false)
const download_journal_files = async (journal_uuid,journal_name)=>{
  console.log(journal_uuid)
  journal_files.value = (await axios.get(BaseUrl+"/api/f/about/journal/file?journal_uuid="+journal_uuid)).data;
  file_table.value = true;
  selected_journal_name.value = journal_name;
}

const handleClose = (done: () => void) => {
    file_table.value = false; // 显式地关闭抽屉
    done();
}

const goToConferencePage = (conferenceName,uuid,conferenceSecondTitle)=>{
  console.log(conferenceName,uuid);
  router.push({
    path:'/conference',
    query:{
      conference_name:conferenceName,
      conference_uuid:uuid,
      conference_second_title:conferenceSecondTitle
    }
  })
}
</script>

<style scoped>
.title {
  color: #000;
  text-align: justify;
  font-family: Arial;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.9px;
}

.images-container {
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  /* 允许换行 */
  gap: 1.6vw;
  /* 设置图像之间的间距 */
  justify-content: flex-start;
  /* 让图像靠左对齐 */
  width: 100%;
}

.image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
</style>