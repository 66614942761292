import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","padding":"10px 0px","flex-direction":"column","align-items":"flex-start","justify-content":"flex-start","align-self":"stretch"} }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "index-box" }
const _hoisted_4 = { class: "project-title" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { style: {"display":"flex","justify-content":"flex-end","width":"95%"} }
const _hoisted_7 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","align-items":"flex-start","flex":"1 0 0","align-self":"stretch"}
}
const _hoisted_8 = { style: {"display":"flex","justify-content":"space-between","align-items":"center","align-self":"stretch","padding":"0px","margin-bottom":"0"} }
const _hoisted_9 = { style: {"margin":"0","color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal"} }
const _hoisted_10 = { style: {"display":"flex","padding":"0px","justify-content":"flex-end","align-items":"center","gap":"10px","align-self":"stretch"} }
const _hoisted_11 = { style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400"} }
const _hoisted_12 = ["href"]

import { onMounted, ref, nextTick } from 'vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
 const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";
const obs = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/data/"


export default /*@__PURE__*/_defineComponent({
  __name: 'DataDatailView',
  setup(__props) {

const data_detail_list = ref([]);
const quill_list = ref<Array<Quill>>([]);

const file_table = ref(false)
const selectedData = ref(null);

const handleClose = (done: () => void) => {
    file_table.value = false; // 显式地关闭抽屉
    done();
    selectedData.value = null; // 清除选中的项目
}

onMounted(async () => {
    document.title="北京城市实验室-数据发布"
    const response = await axios.get(BaseUrl + '/api/f/data/details');
    data_detail_list.value = response.data;
    data_detail_list.value.reverse();
    nextTick(() => {
        for (let index = 0; index < data_detail_list.value.length; index++) {
            const viewerId = `#viewer${index}`;
            const readonlyquill = new Quill(viewerId, {
                theme: 'snow',
                readOnly: true,
                modules: {
                    toolbar: false,
                }
            });
            readonlyquill.root.innerHTML = data_detail_list.value[index].data_content;
            quill_list.value.push(readonlyquill);
        }

        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' }) // 平滑滚动到目标元素
            }
        }
    });

    console.log("初始化完成");
});

return (_ctx: any,_cache: any) => {
  const _component_P = _resolveComponent("P")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_P, { style: {"margin":"0","flex":"1 0 0","color":"#000","text-align":"justify","font-family":"Arial","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"24px","letter-spacing":"0.4px"} }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" This channel would release Beijing, or the whole China, micro-data and maps (e.g. road networks, parcels, human mobility, historical city maps) for the BCL research fellows and external researchers. There are three levels of data access, free download, email request, and shared among research fellows / student members. ")
        ])),
        _: 1
      }),
      _cache[3] || (_cache[3] = _createElementVNode("p", { style: {"margin":"0","flex":"1 0 0","color":"#000","font-family":"Arial","font-size":"16px","font-style":"normal","font-weight":"700","line-height":"24px","letter-spacing":"0.4px"} }, " How to cite: ", -1)),
      _createVNode(_component_P, { style: {"margin":"0","flex":"1 0 0","color":"#000","text-align":"justify","font-family":"Arial","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"24px","letter-spacing":"0.4px"} }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Beijing City Lab, Year, Data ID, Data Name, https://www.beijingcitylab.org "),
          _createElementVNode("br", null, null, -1),
          _createTextVNode(" E.g. Beiing City Lab, 2013, Data 8, Housing price in Beijing, https://www.beijingcitylab.org ")
        ])),
        _: 1
      }),
      _cache[4] || (_cache[4] = _createElementVNode("p", { style: {"margin":"0","flex":"1 0 0","color":"#000","font-family":"Arial","font-size":"16px","font-style":"italic","font-weight":"400","line-height":"24px","letter-spacing":"0.4px"} }, " (For the dataset from external source other than BCL, we would recommend you to cite the original source) ", -1))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data_detail_list.value, (data_detail, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "project-container",
        id: `data-index-${data_detail.data_ID}`
      }, [
        _createVNode(_component_el_divider),
        _createVNode(_component_el_row, { class: "project-row" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_image, {
                  class: "project-image",
                  src: data_detail.data_images[0]?.data_image_link || defaultImage,
                  fit: "scale-down",
                  "preview-src-list": data_detail.data_images.map(img => img.data_image_link)
                }, null, 8, ["src", "preview-src-list"]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", null, _toDisplayString(data_detail.data_ID), 1)
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_el_col, {
              span: 16,
              class: "project-details"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("h2", _hoisted_4, _toDisplayString(data_detail.data_name), 1)
                ]),
                _createElementVNode("div", {
                  id: 'viewer' + index,
                  class: "richtext"
                }, null, 8, _hoisted_5),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_button, {
                    class: "download-bottom",
                    onClick: () => { selectedData.value = data_detail; file_table.value = true }
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Download")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ], 8, _hoisted_2))
    }), 128)),
    _createVNode(_component_el_drawer, {
      modelValue: file_table.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((file_table).value = $event)),
      title: "Files",
      direction: "rtl",
      size: "30%",
      "before-close": handleClose,
      style: {"color":"#000","font-family":"Arial","font-size":"20px","font-style":"italic","font-weight":"700","line-height":"normal"}
    }, {
      default: _withCtx(() => [
        (selectedData.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedData.value.data_files, (file, file_index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: file_index,
                  style: {"margin":"0","display":"flex","flex-direction":"column","align-items":"flex-start","align-self":"stretch"}
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_el_tooltip, {
                      class: "box-item",
                      effect: "dark",
                      content: /\.[a-zA-Z0-9]+$/.test(file.data_file_name)
                        ? file.data_file_name
                        : decodeURIComponent(file.data_file_link.substring(file.data_file_link.lastIndexOf('/') + 1)),
                      placement: "left"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_text, {
                          style: {"color":"#000","font-family":"Inter","font-size":"16px","font-style":"normal","font-weight":"400","line-height":"normal","margin-top":"5px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","width":"18vw"},
                          truncated: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(/\.[a-zA-Z0-9]+$/.test(file.data_file_name)
                                    ? file.data_file_name
                                    :
                                    decodeURIComponent(file.data_file_link.substring(file.data_file_link.lastIndexOf('/')
                                        + 1))), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createElementVNode("p", _hoisted_9, _toDisplayString(file.data_file_size), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, [
                      _createElementVNode("a", {
                        href: file.data_file_link,
                        style: {"color":"inherit"}
                      }, "Download", 8, _hoisted_12)
                    ])
                  ]),
                  _createVNode(_component_el_divider, { style: {"margin":"3px"} })
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})