<template>
    <div class = "item-table">
        <div class = "item-row" v-for="(item_row, index_row) in groupedItems" :key="index_row">
            <div class = "item" v-for="(item, itemIndex) in item_row" :key="itemIndex">
                <div class="person-data">
                    <a v-if="item.personalPage" :href="item.personalPage" target="_blank"
                    style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 700;line-height: normal;white-space: nowrap!important;
                    margin: 0;margin-bottom: 15px!important;">
                       {{ item.name }}
                </a>
                <p v-else style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 700;line-height: normal;white-space: nowrap!important;
                    margin: 0;margin-bottom: 15px!important;">
                    {{ item.name }}
                </p>

                    <div style="display: flex;flex-direction: column;align-items: flex-start;align-self: stretch;">
                        <div style="display: flex;align-items: flex-start;gap:5px;"> 
                            <el-icon style="width: 14px;height: 14px;padding-top: 3px;"><Avatar /></el-icon>
                            <p style="width: 18vw; color: #000;font-family: Inter;font-size: 14px;font-style: normal;font-weight: 400;line-height: normal;
                            white-space: normal; word-wrap: break-word;margin: 0;margin-bottom: 5px!important;">
                                {{ item.memberDescribe }}
                            </p>
                        </div>
                        <div style="display: flex;align-items: center;gap:5px;"> 
                            <el-icon style="width: 14px;height: 14px;"><Aim /></el-icon>
                            <p style="width: 18vw; color: #000;font-family: Inter;font-size: 14px;font-style: normal;font-weight: 400;line-height: normal;
                            white-space: normal; word-wrap: break-word;margin: 0;margin-bottom: 5px!important;">
                                {{ item.major }}
                            </p>
                        </div>
                        <div style="display: flex;align-items: center;gap:5px;"> 
                            <el-icon style="width: 14px;height: 14px;padding-top: 3px;"><Message /></el-icon>
                            <p style="width: 18vw; color: #000;font-family: Inter;font-size: 14px;font-style: normal;font-weight: 400;line-height: normal;margin: 0;">
                                {{ item.mail }}
                            </p>
                        </div>
                    </div>
                </div>
                <el-avatar  style="width: 7vw;height: 7vw;background-color: white;border-style:solid;border-width: 2px;border-color: rgba(31,31,31,0.3);" :src="item.imageLink" fit="scale-down"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps,computed } from 'vue'
// 定义组件接收的props类型
interface Member {
  name: string
  memberDescribe: string
  mail:string
  personalPage:string
  major:string
  imageLink:string
}

const props = defineProps<{
  items: Member[]
}>()

// 将items按每两个分组
const groupedItems = computed(() => {
  const groups: Member[][] = []
  for (let i = 0; i < props.items.length; i += 2) {
    groups.push(props.items.slice(i, i + 2))
  }
  return groups
})
</script>
<style scoped>
.item-table{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
}


.item-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.item{
    display: flex;
    width: 45%;
    padding: 10px 10px;
    justify-content: space-between;
    align-items: flex-start;
}

.person-data{
    display: flex;
    width: 25%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
}
</style>