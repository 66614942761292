<template>
    <div
        style="display: flex;padding: 10px 0px;flex-direction: column;align-items: flex-start;justify-content: flex-start;align-items: flex-start;align-self: stretch;">
        <P
            style="margin: 0;flex: 1 0 0;color: #000;text-align: justify;font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px; /* 150% */letter-spacing: 0.4px;">
            This channel would release Beijing, or the whole China, micro-data and maps (e.g. road networks, parcels,
            human
            mobility, historical city maps) for the BCL research fellows and external researchers. There are three
            levels of
            data access, free download, email request, and shared among research fellows / student members.
        </P>
        <p
            style="margin: 0;flex: 1 0 0;color: #000;font-family: Arial;font-size: 16px;font-style: normal;font-weight: 700;line-height: 24px;letter-spacing: 0.4px;">
            How to cite:
        </p>
        <P
            style="margin: 0;flex: 1 0 0;color: #000;text-align: justify;font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px; /* 150% */letter-spacing: 0.4px;">
            Beijing City Lab, Year, Data ID, Data Name, https://www.beijingcitylab.org <br />
            E.g. Beiing City Lab, 2013, Data 8, Housing price in Beijing, https://www.beijingcitylab.org
        </P>
        <p
            style="margin: 0;flex: 1 0 0;color: #000;font-family: Arial;font-size: 16px;font-style: italic;font-weight: 400;line-height: 24px;letter-spacing: 0.4px;">
            (For the dataset from external source other than BCL, we would recommend you to cite the original source)
        </p>
    </div>
   
    <div v-for="(data_detail, index) in data_detail_list" :key="index" class="project-container" :id="`data-index-${data_detail.data_ID}`">
        <el-divider></el-divider>
        <el-row class="project-row">
            <el-col :span="8">
                <el-image class="project-image" :src="data_detail.data_images[0]?.data_image_link || defaultImage"
                    fit="scale-down" :preview-src-list="data_detail.data_images.map(img => img.data_image_link)" />
                <div class="index-box">
                    <p>{{ data_detail.data_ID }}</p>
                </div>
            </el-col>
            <el-col :span="16" class="project-details">
                <div>
                    <h2 class="project-title">
                        {{ data_detail.data_name }}
                    </h2>
                </div>
                <div :id="'viewer' + index" class="richtext"></div>
                <div style="display: flex;justify-content:flex-end;width: 95%; ">
                    <el-button class="download-bottom" @click="() => { selectedData = data_detail; file_table = true }">
                        Download</el-button>
                </div>

            </el-col>
        </el-row>

    </div>

    <el-drawer v-model="file_table" title="Files" direction="rtl" size="30%" :before-close="handleClose"
        style="color: #000;font-family: Arial;font-size: 20px;font-style: italic;font-weight: 700;line-height: normal;">
        <div v-if="selectedData"
            style="display: flex;flex-direction: column;align-items: flex-start;flex: 1 0 0;align-self: stretch;">
            <div v-for="(file, file_index) in selectedData.data_files" :key="file_index"
                style="margin:0;display: flex;flex-direction: column;align-items: flex-start;align-self: stretch;">
                <div
                    style="display: flex;justify-content: space-between;align-items: center;align-self: stretch;display: flex;padding: 0px;align-items: center;margin-bottom: 0;">
                    <el-tooltip class="box-item" effect="dark" :content="/\.[a-zA-Z0-9]+$/.test(file.data_file_name)
                        ? file.data_file_name
                        : decodeURIComponent(file.data_file_link.substring(file.data_file_link.lastIndexOf('/') + 1))"
                        placement="left">
                        <el-text style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;
                    margin-top: 5px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:18vw ;"
                            truncated>
                            {{
                                /\.[a-zA-Z0-9]+$/.test(file.data_file_name)
                                    ? file.data_file_name
                                    :
                                    decodeURIComponent(file.data_file_link.substring(file.data_file_link.lastIndexOf('/')
                                        + 1))
                            }}
                        </el-text>
                    </el-tooltip>
                    <p
                        style="margin:0;color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;">
                        {{ file.data_file_size }}
                    </p>
                </div>
                <div
                    style="display: flex;padding: 0px;justify-content: flex-end;align-items: center;gap: 10px;align-self: stretch;">
                    <p style="color: #000;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 400;">
                        <a :href="file.data_file_link" style="color: inherit;">Download</a>
                    </p>
                </div>
                <el-divider style="margin: 3px;"></el-divider>
            </div>
        </div>
    </el-drawer>

</template>


<script lang="ts" setup>
import { onMounted, ref, nextTick, defineProps } from 'vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

//const BaseUrl = "http://120.46.82.26:8002"
//const BaseUrl = "https://120.46.82.26:443";
 const BaseUrl = "https://api.beijingcitylab.org:8080"
const defaultImage = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/404.jpg";
const obs = "https://bcl-serve.obs.cn-north-4.myhuaweicloud.com/data/"

const data_detail_list = ref([]);
const quill_list = ref<Array<Quill>>([]);

const file_table = ref(false)
const selectedData = ref(null);

const handleClose = (done: () => void) => {
    file_table.value = false; // 显式地关闭抽屉
    done();
    selectedData.value = null; // 清除选中的项目
}

onMounted(async () => {
    document.title="北京城市实验室-数据发布"
    const response = await axios.get(BaseUrl + '/api/f/data/details');
    data_detail_list.value = response.data;
    data_detail_list.value.reverse();
    nextTick(() => {
        for (let index = 0; index < data_detail_list.value.length; index++) {
            const viewerId = `#viewer${index}`;
            const readonlyquill = new Quill(viewerId, {
                theme: 'snow',
                readOnly: true,
                modules: {
                    toolbar: false,
                }
            });
            readonlyquill.root.innerHTML = data_detail_list.value[index].data_content;
            quill_list.value.push(readonlyquill);
        }

        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' }) // 平滑滚动到目标元素
            }
        }
    });

    console.log("初始化完成");
});
</script>

<style scoped>
.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #FFF;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.5px;
    padding: 10px 10px 10px 10px;

    text-align: center;
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(232, 232, 232, 0.51);
}

.project-container {
    background-color: white;

}

.project-row {
    display: flex;
    align-items: stretch;
    margin-top: 20px;
    margin-bottom: 20px;
}

.project-image {
    width: 21vw;
    height: 21vw;

}

.index-box {
    position: absolute;
    top: 8%;
    left: 1%;
    transform: translate(-50%, -50%);
    background: rgba(0, 36, 128, 0.80);
    color: #FFF;
    font-family: Arial;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1px;
    padding: 0;
    text-align: center;
    width: 3vw;
    height: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-details {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    height: 21vw;
    padding-left: 20px;

    box-sizing: border-box;
}


.project-title {
    width: 38vw;
    font-family: yale, TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
    margin-left: 1vw;
    margin-top: 0;
    margin-bottom: 0;

    color: #000;
    font-family: Arial;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.5px;

}

.project-subtitle {
    width: 95%;
    font-family: yale, TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
    margin-left: 1vw;
    margin-top: 0;
    margin-bottom: 0;
}

.richtext {
    flex: 1;
    width: 95%;
    min-height: 0;
    overflow-y: auto;
    border: none !important;
    box-shadow: none !important;
    margin-top: 5px;
    margin-bottom: 10px;
}

.download-bottom {
    display: flex;
    height: 0.6vh;
    padding: 16px 32px;
    align-items: center;
    gap: 8px;
    background: var(--Base-Black-100, #222325);
    color: var(--Base-White-100, #FFF);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}
</style>
