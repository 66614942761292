import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';



import 'quill/dist/quill.snow.css'; // Quill 样式
import 'highlight.js/styles/atom-one-dark.css'; // Highlight.js 样式
import 'katex/dist/katex.min.css'; // KaTeX 样式
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

// 使用插件
app.use(ElementPlus);
app.use(router);

// 注册全局组件
app.component('QuillEditor', QuillEditor);

// 挂载应用
app.mount('#app');
